
export const RUOLO_AMMINISTRATORE = 'Amministratore';
export const RUOLO_OPERATORE = 'Operatore';

export const ruoli = [
    {
        value: RUOLO_AMMINISTRATORE,
        description: "Amministratore"
    },
    {
        value: RUOLO_OPERATORE,
        description: "Operatore"
    },
];
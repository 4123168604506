// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created, updated} from "../redux/utente.actions";
import GraphqlClient from "../../../client/graphql.client";
import Select from "react-select";

import {TextInputHook} from "../../../hooks/text_input.hook";
import {useSelector} from "react-redux";

const UtenteUpdateComponent: FunctionComponent = (props: any) => {

    const utente_nome = TextInputHook(props.data.nome);
    const utente_cognome = TextInputHook(props.data.cognome);
    const utente_email = TextInputHook(props.data.email);

    const [commessa, setCommessa] = useState(props.data.commessa);
    const [utente_ruolo, setUtente_ruolo] = useState(props.data.ruolo);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const elencoCommesse = useSelector(state => state.commesse.data)

    const elencoRuoli = [{
        value: "Amministratore",
        description: "Amministratore",
    }, {
        value: "Operatore",
        description: "Operatore",
    },]


    const salva = async () => {
        try {
            setLoading(true);

            const utente = {
                commessaId: commessa.id,
                nome: utente_nome.getValue(),
                cognome: utente_cognome.getValue(),
                ruolo: utente_ruolo.value,
                email: utente_email.getValue(),
                tags: []
            }


            const res = await GraphqlClient.aggiornaUtente(
                {utente}
            );

            store.dispatch(updated(res.aggiornaUtente)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };


    const caricaDati = () => {
        elencoRuoli.forEach((r) => {
            if (r.description == props.data.ruolo) {
                setUtente_ruolo(r)
                return
            }
        })
        elencoCommesse.forEach((r) => {
            if (r.id == props.data.commessaId) {
                setCommessa(r)
                return
            }
        })
    }

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

        caricaDati()

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo</h6>
            </div>
            <ModalBody>


                <FormGroup>
                    <Label for="utente_nome">Nome</Label>
                    <Input
                        {...utente_nome.input}
                        name="utente_nome"
                        id="utente_nome"
                        placeholder="Inserisci nome"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="utente_cognome">Cognome</Label>
                    <Input
                        {...utente_cognome.input}
                        name="utente_cognome"
                        id="utente_cognome"
                        placeholder="Inserisci cognome"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="ruolo">Ruolo</Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={utente_ruolo}
                        getOptionLabel={(item) => item.description}
                        onChange={value =>
                            setUtente_ruolo(value)
                        }
                        options={elencoRuoli}
                        placeholder="Seleziona un ruolo"
                    />
                    <FormText color="muted">
                        Ruolo a cui si riferisce l'utente
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="ruolo">Commessa</Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={commessa}
                        getOptionLabel={(item) => item.descrizione}
                        onChange={value =>
                            setCommessa(value)
                        }
                        options={elencoCommesse}
                        placeholder="Seleziona commessa"
                    />
                    <FormText color="muted">
                        Ruolo a cui si riferisce l'utente
                    </FormText>
                </FormGroup>

                <FormGroup>
                    <Label for="utente_email">E-Mail</Label>
                    <Input
                        {...utente_email.input}
                        name="utente_email"
                        id="utente_email"
                        placeholder="Inserisci email"
                    />
                </FormGroup>

                {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                <button style={{display: 'none'}} type="submit"/>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default UtenteUpdateComponent;

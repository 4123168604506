// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, Row, Col, Card} from "reactstrap";
import React, {FunctionComponent, useState, useEffect, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created, updated} from "../redux/commesse.actions";
import GraphqlClient from "../../../client/graphql.client";
import ClassNames from 'classnames';

import {TextInputHook} from "../../../hooks/text_input.hook";
import Select from "react-select";
import {useSelector} from 'react-redux'
import CommesseMetasComponent from "./commesse.metas.component"

const CommesseCreateComponent: FunctionComponent = (props: any) => {
    const input_descrizione = TextInputHook(props?.data?.descrizione);
    const input_riferimento = TextInputHook(props?.data?.riferimento);
    const input_ultimoOggetto = TextInputHook(props?.data?.ultimoOggetto)

    const [input_cliente, setInput_cliente] = useState(props?.data?.committente);
    const [input_committente, setInput_committente] = useState(props?.data?.cliente);
    const [metas, setMetas] = useState(props?.data?.metas);


    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const elencoClienti = useSelector(state => state.societa.data.map(item => {
        let v = {...item, key: item.id}
        return v
    }));

    const commesseMetasComponentRef = useRef(null);

    const salva = async () => {
        let metas = commesseMetasComponentRef.current.getMetas();
        metas = metas ? metas : []
        console.log("MMMMM", metas)
        try {
            setLoading(true);
            const input = {
                id: "",
                descrizione: input_descrizione.getValue(),
                riferimento: input_riferimento.getValue(),
                committente: JSON.stringify(input_committente),
                cliente: JSON.stringify(input_cliente),
                metas: JSON.stringify(metas),
                ultimooggetto: input_ultimoOggetto.getValue(),
            }

            const res = await GraphqlClient.creaCommesse({input})
            store.dispatch(created(res.creaCommesse)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName={ClassNames("modal-black", 'extra-large-sparkle-modal')}
            isOpen={true}
            toggle={props.toggle}
        >
            <Row>
                <Col xs={12} md={12}>
                    <div className="modal-header justify-content-center">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={props.toggle}
                        >
                            <i className="tim-icons icon-simple-remove"/>
                        </button>
                        <h6 className="title title-up">Nuovo</h6>
                    </div>

                </Col>

                <Col xs={12} md={6} style={{paddingLeft: 50, paddingTop: 40}}>
                    <Card>
                        <ModalBody>
                            <form onSubmit={e => {
                                e.preventDefault();
                                salva();
                            }}>
                                <FormGroup>
                                    <Label for="input_descrizione">Descrizione</Label>
                                    <Input
                                        {...input_descrizione.input}
                                        name="input_descrizione"
                                        id="input_descrizione"
                                        placeholder="Inserisci descrizione"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="input_riferimento">Riferimento</Label>
                                    <Input
                                        {...input_riferimento.input}
                                        name="input_riferimento"
                                        id="input_riferimento"
                                        placeholder="Inserisci riferimento"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="input_committente_id">Cliente committente</Label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="input_cliente_id"
                                        id="input_cliente_id"
                                        value={input_committente}
                                        getOptionValue={(item) => item}
                                        getOptionLabel={(item) => item.ragioneSociale}
                                        onChange={value =>
                                            setInput_committente(value)
                                        }
                                        options={[...elencoClienti]}
                                        placeholder="Seleziona committente"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="input_cliente">Cliente diretto</Label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="input_cliente_id"
                                        id="input_cliente_id"
                                        value={input_cliente}
                                        getOptionValue={(item) => {
                                            return item
                                        }}
                                        getOptionLabel={(item) => {
                                            return item.ragioneSociale
                                        }}
                                        onChange={value =>
                                            setInput_cliente(value)
                                        }
                                        options={[...elencoClienti]}
                                        placeholder="Seleziona un cliente"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="input_ultimoOggetto">Ultimo oggetto</Label>
                                    <Input
                                        {...input_ultimoOggetto.input}
                                        name="input_ultimoOggetto"
                                        id="input_ultimoOggetto"
                                        placeholder="Inserisci PALLET/SCATOLA/FALDONE/PRATICA"
                                    />
                                </FormGroup>

                                {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                                <button style={{
                                    display:
                                        'none'
                                }} type="submit"/>
                            </form>
                            <CSSTransition
                                in={submitError}
                                appear={true}
                                unmountOnExit
                                classNames="error"
                                timeout={300}
                            >
                                <blockquote className="blockquote blockquote-danger">
                                    <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                                </blockquote>
                            </CSSTransition>

                        </ModalBody>
                    </Card>
                </Col>
                <Col xs={12} md={6} style={{paddingRight: 50, paddingTop: 40}}>
                    <Card>
                        <ModalBody>

                            <FormGroup>
                                <CommesseMetasComponent
                                    ref={commesseMetasComponentRef}
                                    metas={metas}>
                                </CommesseMetasComponent>
                            </FormGroup>
                        </ModalBody>
                    </Card>
                </Col>
            </Row>

            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default CommesseCreateComponent;

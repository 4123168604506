import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/utente.reducer';
import UtenteCreateComponent from "./components/utente.create.component";
import UtenteUpdatePasswordComponent from "./components/utente.updatePassword.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/utente.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import UtenteUpdateComponent from "./components/utente.update.component";


class utenteComponent extends Component {

    state = {
        data: [],
        loading: false,
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteAlert: false,
        selectedRecord: {},
        showChangePwd: false
    }

    caricaDati = async () => {
        try {
            const response = await GraphqlClient.utenti(

            );
            store.dispatch(readedAll(response.utenti)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }


    componentDidMount() {
        this.caricaDati();
    }


    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }


    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecord: record
        })
    }

    toggleShowChangePwd = (record) => {
        this.setState({
            showChangePwd: !this.state.showChangePwd,
            selectedRecord: record
        })
    }
    elimina = async () => {
        try {
            this.toggleDeleteAlert(this.state.selectedRecord);
            store.dispatch(requestDelete(this.state.selectedRecord.id))
            await GraphqlClient.eliminaUtente({email: this.state.selectedRecord.email});
            store.dispatch(deleted({id: this.state.selectedRecord.id})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare il record', e);
            store.dispatch(deleteError(this.state.selectedRecord.id));
        }

    }

    toggleDeleteAlert = (record) => {
        this.setState({
            showDeleteAlert: !this.state.showDeleteAlert,
            selectedRecord: record
        });
    }

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }

            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowChangePwd(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-key-25"/>
            </Button>
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>
        </div>
    );

    render() {
        return (
            <>

                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {this.state.selectedRecord?.email}
                        </ReactBSAlert> : null
                }
                <UtenteCreateComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {this.state.showChangePwd ? <UtenteUpdatePasswordComponent data={this.state.selectedRecord}
                                                                           toggle={this.toggleShowChangePwd}/> : null
                }

                {this.state.showUpdateModal ? <UtenteUpdateComponent show={this.state.showUpdateModal}
                                                                     data={this.state.selectedRecord}
                                                                     toggle={this.toggleShowUpdateModal}/> : null
                }


                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>

                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo lotto<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[

                                            {
                                                Header: "nome",
                                                accessor: "nome"
                                            },
                                            {
                                                Header: "cognome",
                                                accessor: "cognome"
                                            },
                                            {
                                                Header: "email",
                                                accessor: "email"
                                            },
                                            {
                                                Header: "ruolo",
                                                accessor: "ruolo"
                                            },

                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            }]
                                        }
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "utente.data", []),
        loading: get(state, "utente.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(utenteComponent)

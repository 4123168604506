import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
type Maybe<T> = T | null;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  Time: any;
  Upload: any;
};






type BarcodeSequenza = {
  __typename?: 'BarcodeSequenza';
  id: Scalars['String'];
  dataMovimento: Scalars['Int'];
  utente: Scalars['String'];
  barcode: Scalars['String'];
};

type Commesse = {
  __typename?: 'Commesse';
  id: Scalars['String'];
  descrizione: Scalars['String'];
  riferimento: Scalars['String'];
  committente: Scalars['Json'];
  cliente: Scalars['Json'];
  metas: Scalars['Json'];
  ultimooggetto: Scalars['String'];
};

type Ddt = {
  __typename?: 'Ddt';
  id: Scalars['String'];
  numero: Scalars['String'];
  data: Scalars['Int'];
  utente: Scalars['String'];
  commessa: Scalars['Json'];
  metas: Scalars['Json'];
  causaleTrasporto: Scalars['String'];
  destinatario: Scalars['Json'];
  destinatarioLuogo: Scalars['Json'];
  righe: Scalars['Json'];
  aspettoEsteriore: Scalars['String'];
  numeroColli: Scalars['Int'];
  pesoKg: Scalars['Float'];
  inizioTrasportoAMezzo: Scalars['String'];
  numeroProggressivo: Scalars['String'];
};

type DdtRiga = {
  __typename?: 'DdtRiga';
  id: Scalars['String'];
  qta: Scalars['Int'];
  descrizione: Scalars['String'];
  import: Scalars['Float'];
  metas: Scalars['Json'];
};

type EtichetteBobina = {
  __typename?: 'EtichetteBobina';
  id: Scalars['String'];
  dataCreazione: Scalars['Int'];
  descrizione: Scalars['String'];
  commessa: Scalars['Json'];
  utente: Scalars['String'];
  dal: Scalars['Int'];
  al: Scalars['Int'];
  tipologia: Scalars['String'];
  zpl: Scalars['String'];
  dataArray: Scalars['String'];
  width_mm: Scalars['Float'];
  height_mm: Scalars['Float'];
  stampata: Scalars['Boolean'];
};

type InputAggiornaUtente = {
  commessaId: Scalars['String'];
  nome: Scalars['String'];
  cognome: Scalars['String'];
  ruolo: Scalars['String'];
  email: Scalars['String'];
  tags: Array<Scalars['String']>;
};

type InputBarcodeSequenza = {
  id: Scalars['String'];
  dataMovimento: Scalars['Int'];
  utente: Scalars['String'];
  barcode: Scalars['String'];
};

type InputCommesse = {
  id: Scalars['String'];
  descrizione: Scalars['String'];
  riferimento: Scalars['String'];
  committente: Scalars['Json'];
  cliente: Scalars['Json'];
  metas: Scalars['Json'];
  ultimooggetto: Scalars['String'];
};

type InputDdt = {
  id: Scalars['String'];
  numero: Scalars['String'];
  data: Scalars['Int'];
  utente: Scalars['String'];
  commessa: Scalars['Json'];
  metas: Scalars['Json'];
  causaleTrasporto: Scalars['String'];
  destinatario: Scalars['Json'];
  destinatarioLuogo: Scalars['Json'];
  righe: Scalars['Json'];
  aspettoEsteriore: Scalars['String'];
  numeroColli: Scalars['Int'];
  pesoKg: Scalars['Float'];
  inizioTrasportoAMezzo: Scalars['String'];
  numeroProggressivo: Scalars['String'];
};

type InputDdtRiga = {
  id: Scalars['String'];
  qta: Scalars['Int'];
  descrizione: Scalars['String'];
  import: Scalars['Float'];
  metas: Scalars['Json'];
};

type InputEtichetteBobina = {
  id: Scalars['String'];
  dataCreazione: Scalars['Int'];
  descrizione: Scalars['String'];
  commessa: Scalars['Json'];
  utente: Scalars['String'];
  quantita: Scalars['Int'];
  tipologia: Scalars['String'];
  zpl: Scalars['String'];
  dataArray: Scalars['String'];
  width_mm: Scalars['Float'];
  height_mm: Scalars['Float'];
  stampata: Scalars['Boolean'];
};

type InputMovimenti = {
  dataMovimento: Scalars['Int'];
  tipologiaMovimento: Scalars['String'];
  tipologiaBarcodeAllocato: Scalars['String'];
  barcodeUbicazione: Scalars['String'];
  tipologiaUbicazione: Scalars['String'];
  commessa: Scalars['Json'];
  utente?: Maybe<Scalars['String']>;
  barcodeAllocati: Array<Scalars['String']>;
  metas?: Maybe<Scalars['Json']>;
};

type InputMovimento = {
  dataMovimento: Scalars['Int'];
  utente: Scalars['String'];
  tipologiaMovimento: Scalars['String'];
  barcodeAllocato: Scalars['String'];
  tipologiaBarcodeAllocato: Scalars['String'];
  barcodeUbicazione: Scalars['String'];
  tipologiaUbicazione: Scalars['String'];
  commessa: Scalars['Json'];
  metas: Scalars['Json'];
};

type InputPratica = {
  id: Scalars['String'];
  dataPrimoMovimento: Scalars['Int'];
  dataUltimoMovimento: Scalars['Int'];
  riferimento: Scalars['String'];
  tipologiaRiferimento: Scalars['String'];
  barcodeFaldone: Scalars['String'];
  tipologiaPratica: Scalars['String'];
  scatola: Scalars['String'];
  scatolaAnno: Scalars['String'];
  pallet: Scalars['String'];
  idsMovimenti: Array<Scalars['String']>;
  cliente?: Maybe<Scalars['Json']>;
  cliente_committente?: Maybe<Scalars['Json']>;
  commessa?: Maybe<Scalars['Json']>;
};

type InputReport = {
  id: Scalars['String'];
  dataCreazione: Scalars['Int'];
  utente: Scalars['String'];
  commessaId: Scalars['String'];
  descrizione: Scalars['String'];
  contenuto: Scalars['String'];
  tipologiaReport: Scalars['String'];
  querySql: Scalars['String'];
};

type InputSocieta = {
  id: Scalars['String'];
  ragioneSociale: Scalars['String'];
  indirizzo: Scalars['String'];
  comune: Scalars['String'];
  partitaIva: Scalars['String'];
  recapito: Scalars['String'];
  companyType: Scalars['String'];
};

type InputUtente = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  password: Scalars['String'];
  confermaPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  dataNascita: Scalars['Int'];
  provider: Scalars['String'];
  tags: Array<Scalars['String']>;
  ruolo: Scalars['String'];
  commessaId?: Maybe<Scalars['String']>;
};

type InputUtenteConPassword = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  ruolo: Scalars['String'];
  password: Scalars['String'];
  confermaPassword: Scalars['String'];
  email: Scalars['String'];
  tags: Array<Scalars['String']>;
  commessaId?: Maybe<Scalars['String']>;
};


type Movimento = {
  __typename?: 'Movimento';
  id: Scalars['String'];
  dataMovimento: Scalars['Int'];
  utente: Scalars['String'];
  tipologiaMovimento: Scalars['String'];
  barcodeAllocato: Scalars['String'];
  tipologiaBarcodeAllocato: Scalars['String'];
  barcodeUbicazione: Scalars['String'];
  tipologiaUbicazione: Scalars['String'];
  commessa: Scalars['Json'];
  metas: Scalars['Json'];
  da: Scalars['String'];
  a: Scalars['String'];
};

type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  creaCommesse: Commesse;
  eliminaCommesse: Commesse;
  aggiornaCommesse?: Maybe<Commesse>;
  creaDdt: Ddt;
  aggiornaDdt: Ddt;
  eliminaDdt: Ddt;
  creaEtichetteBobina: EtichetteBobina;
  aggiornaEtichetteBobina: EtichetteBobina;
  eliminaEtichetteBobina: ResponseEtichetteBobina;
  stampaEtichetteBobina: ResponseEtichetteBobina;
  downloadEtichetteBobinaPDF: ResponseDownloadEtichetteBobinaPdf;
  creaMovimento: Movimento;
  creaMovimenti: Array<Movimento>;
  eliminaMovimento: Movimento;
  eliminaFaldone: ResponseDelete;
  esportaCsv: Scalars['String'];
  stampaContenutoPallet?: Maybe<Scalars['String']>;
  stampaTestaPacco?: Maybe<Scalars['String']>;
  stampaTestaPaccoByPallet?: Maybe<Scalars['String']>;
  stampaContenutoPalletByPallet?: Maybe<Scalars['String']>;
  stampaReportErrori?: Maybe<Scalars['String']>;
  creaPratica: Pratica;
  eliminaPratica: ResponsePratica;
  generaPratiche?: Maybe<Scalars['String']>;
  creaReport: Report;
  aggiornaReport: Report;
  eliminaReport: Report;
  downloadReport?: Maybe<Scalars['String']>;
  downloadReportByTipologia?: Maybe<Scalars['String']>;
  downloadReportVerbaleCsv?: Maybe<Scalars['String']>;
  downloadReportMaricaCsv?: Maybe<Scalars['String']>;
  downloadReportMaricaFaldoneCsv?: Maybe<Scalars['String']>;
  downloadReportMaricaDorsoduroCsv?: Maybe<Scalars['String']>;
  creaBarcodeSequenza: BarcodeSequenza;
  eliminaBarcodeSequenza: ResponseEliminaSequenza;
  eliminaSequenza: ResponseEliminaSequenza;
  esportaCsvSequenza: Scalars['String'];
  creaSocieta: Societa;
  aggiornaSocieta: Societa;
  eliminaSocieta: Societa;
  creaUtente: TokenDetails;
  aggiornaUtente: Utente;
  eliminaUtente: Utente;
  cambiaPassword: Utente;
};


type MutationCreaCommesseArgs = {
  input: InputCommesse;
};


type MutationEliminaCommesseArgs = {
  id: Scalars['String'];
};


type MutationAggiornaCommesseArgs = {
  input?: Maybe<InputCommesse>;
};


type MutationCreaDdtArgs = {
  movimento: InputDdt;
};


type MutationAggiornaDdtArgs = {
  movimento: InputDdt;
};


type MutationEliminaDdtArgs = {
  id: Scalars['String'];
};


type MutationCreaEtichetteBobinaArgs = {
  input: InputEtichetteBobina;
};


type MutationAggiornaEtichetteBobinaArgs = {
  input: InputEtichetteBobina;
};


type MutationEliminaEtichetteBobinaArgs = {
  id: Scalars['String'];
};


type MutationStampaEtichetteBobinaArgs = {
  id: Scalars['String'];
};


type MutationDownloadEtichetteBobinaPdfArgs = {
  id: Scalars['String'];
};


type MutationCreaMovimentoArgs = {
  movimento: InputMovimento;
};


type MutationCreaMovimentiArgs = {
  movimento: InputMovimenti;
};


type MutationEliminaMovimentoArgs = {
  id: Scalars['String'];
};


type MutationEliminaFaldoneArgs = {
  faldone: Scalars['String'];
};


type MutationEsportaCsvArgs = {
  commessaId: Scalars['String'];
};


type MutationStampaContenutoPalletArgs = {
  ids: Array<Scalars['String']>;
};


type MutationStampaTestaPaccoArgs = {
  ids: Array<Scalars['String']>;
};


type MutationStampaTestaPaccoByPalletArgs = {
  pallets: Array<Scalars['String']>;
};


type MutationStampaContenutoPalletByPalletArgs = {
  pallets: Array<Scalars['String']>;
};


type MutationCreaPraticaArgs = {
  input: InputPratica;
};


type MutationEliminaPraticaArgs = {
  id: Scalars['String'];
};


type MutationCreaReportArgs = {
  input: InputReport;
};


type MutationAggiornaReportArgs = {
  input: InputReport;
};


type MutationEliminaReportArgs = {
  id: Scalars['String'];
};


type MutationDownloadReportArgs = {
  reportid: Scalars['String'];
  filters: Scalars['String'];
};


type MutationDownloadReportByTipologiaArgs = {
  tipologiaReport: Scalars['String'];
  commessaId: Scalars['String'];
  filters: Scalars['String'];
};


type MutationDownloadReportVerbaleCsvArgs = {
  commessaId: Scalars['String'];
  filters: Scalars['String'];
};


type MutationDownloadReportMaricaCsvArgs = {
  commessaId: Scalars['String'];
  filters: Scalars['String'];
};


type MutationDownloadReportMaricaFaldoneCsvArgs = {
  commessaId: Scalars['String'];
  filters: Scalars['String'];
};


type MutationDownloadReportMaricaDorsoduroCsvArgs = {
  commessaId: Scalars['String'];
  filters: Scalars['String'];
};


type MutationCreaBarcodeSequenzaArgs = {
  input: InputBarcodeSequenza;
};


type MutationEliminaBarcodeSequenzaArgs = {
  id: Scalars['String'];
};


type MutationEsportaCsvSequenzaArgs = {
  dataInizio: Scalars['Int'];
  dataFine: Scalars['Int'];
};


type MutationCreaSocietaArgs = {
  input: InputSocieta;
};


type MutationAggiornaSocietaArgs = {
  input: InputSocieta;
};


type MutationEliminaSocietaArgs = {
  id: Scalars['String'];
};


type MutationCreaUtenteArgs = {
  utente?: Maybe<InputUtenteConPassword>;
};


type MutationAggiornaUtenteArgs = {
  utente: InputAggiornaUtente;
};


type MutationEliminaUtenteArgs = {
  email: Scalars['String'];
};


type MutationCambiaPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

type PalletView = {
  __typename?: 'PalletView';
  pallet: Scalars['String'];
  scatola: Scalars['String'];
  faldone: Scalars['String'];
  tipologia: Scalars['String'];
  anno: Scalars['String'];
  numeroFaldoni: Scalars['Int'];
  numeroPratiche: Scalars['Int'];
  da: Scalars['String'];
  a: Scalars['String'];
  metasScatola: Scalars['Json'];
  metasFaldone: Scalars['Json'];
  metasPallet: Scalars['Json'];
};

type Pratica = {
  __typename?: 'Pratica';
  id: Scalars['String'];
  dataPrimoMovimento: Scalars['Int'];
  dataUltimoMovimento: Scalars['Int'];
  riferimento: Scalars['String'];
  tipologiaRiferimento: Scalars['String'];
  barcodeFaldone: Scalars['String'];
  tipologiaPratica: Scalars['String'];
  scatola: Scalars['String'];
  scatolaAnno: Scalars['String'];
  pallet: Scalars['String'];
  idMovimentoBase: Scalars['String'];
  cliente?: Maybe<Scalars['Json']>;
  cliente_committente?: Maybe<Scalars['Json']>;
  commessa?: Maybe<Scalars['Json']>;
};

type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  elencoCommesse: Array<Commesse>;
  commessaById: Commesse;
  commessaFilter: Commesse;
  elencoDdt: Array<Ddt>;
  ddtById: Ddt;
  elencoEtichetteBobina: Array<EtichetteBobina>;
  etichetteBobinaById: EtichetteBobina;
  elencoMovimenti: Array<Movimento>;
  elencoMovimentiRicercaInPeriodo: Array<Movimento>;
  elencoMovimentiUtente: Array<Movimento>;
  elencoMovimentiGiornalieriUtente: Array<Movimento>;
  elencoMovimentiBarcode: Array<Movimento>;
  elencoMovimentiRicerca: Array<Movimento>;
  elencoPallets: Array<Scalars['String']>;
  scaricaReportTestaPacco?: Maybe<Scalars['String']>;
  stampaContenutoPalletByCommessa?: Maybe<Scalars['String']>;
  stampaVerbaleByCommessa?: Maybe<Scalars['String']>;
  elencoStatistiche: Array<Statistiche>;
  elencoPalletView: Array<PalletView>;
  tracciatoCsvPallet: Scalars['String'];
  elencoPratiche: Array<Pratica>;
  elencoPratichePeriodo: Array<Pratica>;
  elencoReports: Array<Report>;
  reportById: Array<Report>;
  elencoBarcodeSequenza: Array<BarcodeSequenza>;
  elencoSocieta: Array<Societa>;
  utenti?: Maybe<Array<Maybe<Utente>>>;
  utenteLoggato: Utente;
  appleSignIn: TokenDetails;
  appleSignUp: TokenDetails;
  login: TokenDetails;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
};


type QueryCommessaByIdArgs = {
  id: Scalars['String'];
};


type QueryCommessaFilterArgs = {
  id: Scalars['String'];
};


type QueryElencoDdtArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


type QueryDdtByIdArgs = {
  id: Scalars['String'];
};


type QueryEtichetteBobinaByIdArgs = {
  id: Scalars['String'];
};


type QueryElencoMovimentiArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


type QueryElencoMovimentiRicercaInPeriodoArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  filter: Scalars['String'];
  limit: Scalars['Int'];
  commessaId: Scalars['String'];
};


type QueryElencoMovimentiUtenteArgs = {
  limit: Scalars['Int'];
};


type QueryElencoMovimentiGiornalieriUtenteArgs = {
  limit: Scalars['Int'];
};


type QueryElencoMovimentiBarcodeArgs = {
  codice: Scalars['String'];
};


type QueryElencoMovimentiRicercaArgs = {
  search: Scalars['String'];
};


type QueryElencoPalletsArgs = {
  commesaIds: Array<Scalars['String']>;
};


type QueryScaricaReportTestaPaccoArgs = {
  ids: Array<Scalars['String']>;
};


type QueryStampaContenutoPalletByCommessaArgs = {
  commessaId: Scalars['String'];
};


type QueryStampaVerbaleByCommessaArgs = {
  commessaId: Scalars['String'];
};


type QueryElencoStatisticheArgs = {
  commessaId: Array<Scalars['String']>;
};


type QueryElencoPalletViewArgs = {
  filter: Scalars['String'];
  commessaId: Scalars['String'];
};


type QueryTracciatoCsvPalletArgs = {
  commessaId: Scalars['String'];
};


type QueryElencoPratichePeriodoArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


type QueryReportByIdArgs = {
  id: Scalars['String'];
};


type QueryAppleSignInArgs = {
  identityToken: Scalars['String'];
};


type QueryAppleSignUpArgs = {
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


type QueryLoginArgs = {
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

type Report = {
  __typename?: 'Report';
  id: Scalars['String'];
  dataCreazione: Scalars['Int'];
  utente: Scalars['String'];
  commessaId: Scalars['String'];
  descrizione: Scalars['String'];
  contenuto: Scalars['String'];
  tipologiaReport: Scalars['String'];
  querySql: Scalars['String'];
};

type ResponseDelete = {
  __typename?: 'ResponseDelete';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  rowAffected: Scalars['Int'];
};

type ResponseDownloadEtichetteBobinaPdf = {
  __typename?: 'ResponseDownloadEtichetteBobinaPDF';
  success: Scalars['Boolean'];
  error: Scalars['String'];
  data: Scalars['String'];
};

type ResponseEliminaSequenza = {
  __typename?: 'ResponseEliminaSequenza';
  success: Scalars['Boolean'];
  rowAffected: Scalars['Int'];
};

type ResponseEtichetteBobina = {
  __typename?: 'ResponseEtichetteBobina';
  success: Scalars['Boolean'];
  rowAffected: Scalars['Int'];
  error: Scalars['String'];
};

type ResponsePratica = {
  __typename?: 'ResponsePratica';
  success: Scalars['Boolean'];
  rowAffected: Scalars['Int'];
  error: Scalars['String'];
};

enum Role {
  Amministratore = 'AMMINISTRATORE',
  Utente = 'UTENTE'
}

type Societa = {
  __typename?: 'Societa';
  id: Scalars['String'];
  ragioneSociale: Scalars['String'];
  indirizzo: Scalars['String'];
  comune: Scalars['String'];
  partitaIva: Scalars['String'];
  recapito: Scalars['String'];
  companyType: Scalars['String'];
};

type Statistiche = {
  __typename?: 'Statistiche';
  tipologia: Scalars['String'];
  conteggio: Scalars['Int'];
  value: Scalars['Json'];
};


type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  utente: Utente;
};


type Utente = {
  __typename?: 'Utente';
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  ruolo: Scalars['String'];
  avatar: Scalars['String'];
  dataNascita: Scalars['Int'];
  provider: Scalars['String'];
  dataRegistrazione: Scalars['Int'];
  createdAt?: Maybe<Scalars['Time']>;
  tags: Array<Scalars['String']>;
  commessaId: Scalars['String'];
};

type AggiornaCommesseMutationVariables = Exact<{
  input?: Maybe<InputCommesse>;
}>;


type AggiornaCommesseMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaCommesse?: Maybe<(
    { __typename?: 'Commesse' }
    & Pick<Commesse, 'id' | 'descrizione' | 'riferimento' | 'committente' | 'cliente' | 'metas' | 'ultimooggetto'>
  )> }
);

type AggiornaDdtMutationVariables = Exact<{
  movimento: InputDdt;
}>;


type AggiornaDdtMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaDdt: (
    { __typename?: 'Ddt' }
    & Pick<Ddt, 'id' | 'numero' | 'data' | 'utente' | 'commessa' | 'metas' | 'causaleTrasporto' | 'destinatario' | 'destinatarioLuogo' | 'righe' | 'aspettoEsteriore' | 'numeroColli' | 'pesoKg' | 'inizioTrasportoAMezzo' | 'numeroProggressivo'>
  ) }
);

type AggiornaEtichetteBobinaMutationVariables = Exact<{
  input: InputEtichetteBobina;
}>;


type AggiornaEtichetteBobinaMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaEtichetteBobina: (
    { __typename?: 'EtichetteBobina' }
    & Pick<EtichetteBobina, 'id' | 'dataCreazione' | 'descrizione' | 'commessa' | 'utente' | 'dal' | 'al' | 'tipologia' | 'zpl' | 'dataArray' | 'width_mm' | 'height_mm' | 'stampata'>
  ) }
);

type AggiornaReportMutationVariables = Exact<{
  input: InputReport;
}>;


type AggiornaReportMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'dataCreazione' | 'utente' | 'commessaId' | 'descrizione' | 'contenuto' | 'tipologiaReport' | 'querySql'>
  ) }
);

type AggiornaSocietaMutationVariables = Exact<{
  input: InputSocieta;
}>;


type AggiornaSocietaMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaSocieta: (
    { __typename?: 'Societa' }
    & Pick<Societa, 'id' | 'ragioneSociale' | 'indirizzo' | 'comune' | 'partitaIva' | 'recapito' | 'companyType'>
  ) }
);

type AggiornaUtenteMutationVariables = Exact<{
  utente: InputAggiornaUtente;
}>;


type AggiornaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaUtente: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
  ) }
);

type CambiaPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


type CambiaPasswordMutation = (
  { __typename?: 'Mutation' }
  & { cambiaPassword: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
  ) }
);

type CreaBarcodeSequenzaMutationVariables = Exact<{
  input: InputBarcodeSequenza;
}>;


type CreaBarcodeSequenzaMutation = (
  { __typename?: 'Mutation' }
  & { creaBarcodeSequenza: (
    { __typename?: 'BarcodeSequenza' }
    & Pick<BarcodeSequenza, 'id' | 'dataMovimento' | 'utente' | 'barcode'>
  ) }
);

type CreaCommesseMutationVariables = Exact<{
  input: InputCommesse;
}>;


type CreaCommesseMutation = (
  { __typename?: 'Mutation' }
  & { creaCommesse: (
    { __typename?: 'Commesse' }
    & Pick<Commesse, 'id' | 'descrizione' | 'riferimento' | 'committente' | 'cliente' | 'metas' | 'ultimooggetto'>
  ) }
);

type CreaDdtMutationVariables = Exact<{
  movimento: InputDdt;
}>;


type CreaDdtMutation = (
  { __typename?: 'Mutation' }
  & { creaDdt: (
    { __typename?: 'Ddt' }
    & Pick<Ddt, 'id' | 'numero' | 'data' | 'utente' | 'commessa' | 'metas' | 'causaleTrasporto' | 'destinatario' | 'destinatarioLuogo' | 'righe' | 'aspettoEsteriore' | 'numeroColli' | 'pesoKg' | 'inizioTrasportoAMezzo' | 'numeroProggressivo'>
  ) }
);

type CreaEtichetteBobinaMutationVariables = Exact<{
  input: InputEtichetteBobina;
}>;


type CreaEtichetteBobinaMutation = (
  { __typename?: 'Mutation' }
  & { creaEtichetteBobina: (
    { __typename?: 'EtichetteBobina' }
    & Pick<EtichetteBobina, 'id' | 'dataCreazione' | 'descrizione' | 'commessa' | 'utente' | 'dal' | 'al' | 'tipologia' | 'zpl' | 'dataArray' | 'width_mm' | 'height_mm' | 'stampata'>
  ) }
);

type CreaMovimentiMutationVariables = Exact<{
  movimento: InputMovimenti;
}>;


type CreaMovimentiMutation = (
  { __typename?: 'Mutation' }
  & { creaMovimenti: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type CreaMovimentoMutationVariables = Exact<{
  movimento: InputMovimento;
}>;


type CreaMovimentoMutation = (
  { __typename?: 'Mutation' }
  & { creaMovimento: (
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  ) }
);

type CreaPraticaMutationVariables = Exact<{
  input: InputPratica;
}>;


type CreaPraticaMutation = (
  { __typename?: 'Mutation' }
  & { creaPratica: (
    { __typename?: 'Pratica' }
    & Pick<Pratica, 'id' | 'dataPrimoMovimento' | 'dataUltimoMovimento' | 'riferimento' | 'tipologiaRiferimento' | 'barcodeFaldone' | 'tipologiaPratica' | 'scatola' | 'scatolaAnno' | 'pallet' | 'idMovimentoBase' | 'cliente' | 'cliente_committente' | 'commessa'>
  ) }
);

type CreaReportMutationVariables = Exact<{
  input: InputReport;
}>;


type CreaReportMutation = (
  { __typename?: 'Mutation' }
  & { creaReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'dataCreazione' | 'utente' | 'commessaId' | 'descrizione' | 'contenuto' | 'tipologiaReport' | 'querySql'>
  ) }
);

type CreaSocietaMutationVariables = Exact<{
  input: InputSocieta;
}>;


type CreaSocietaMutation = (
  { __typename?: 'Mutation' }
  & { creaSocieta: (
    { __typename?: 'Societa' }
    & Pick<Societa, 'id' | 'ragioneSociale' | 'indirizzo' | 'comune' | 'partitaIva' | 'recapito' | 'companyType'>
  ) }
);

type CreaUtenteMutationVariables = Exact<{
  utente?: Maybe<InputUtenteConPassword>;
}>;


type CreaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { creaUtente: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
    ) }
  ) }
);

type DownloadEtichetteBobinaPdfMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type DownloadEtichetteBobinaPdfMutation = (
  { __typename?: 'Mutation' }
  & { downloadEtichetteBobinaPDF: (
    { __typename?: 'ResponseDownloadEtichetteBobinaPDF' }
    & Pick<ResponseDownloadEtichetteBobinaPdf, 'success' | 'error' | 'data'>
  ) }
);

type DownloadReportMutationVariables = Exact<{
  reportid: Scalars['String'];
  filters: Scalars['String'];
}>;


type DownloadReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadReport'>
);

type DownloadReportByTipologiaMutationVariables = Exact<{
  tipologiaReport: Scalars['String'];
  commessaId: Scalars['String'];
  filters: Scalars['String'];
}>;


type DownloadReportByTipologiaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadReportByTipologia'>
);

type DownloadReportMaricaCsvMutationVariables = Exact<{
  commessaId: Scalars['String'];
  filters: Scalars['String'];
}>;


type DownloadReportMaricaCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadReportMaricaCsv'>
);

type DownloadReportMaricaDorsoduroCsvMutationVariables = Exact<{
  commessaId: Scalars['String'];
  filters: Scalars['String'];
}>;


type DownloadReportMaricaDorsoduroCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadReportMaricaDorsoduroCsv'>
);

type DownloadReportMaricaFaldoneCsvMutationVariables = Exact<{
  commessaId: Scalars['String'];
  filters: Scalars['String'];
}>;


type DownloadReportMaricaFaldoneCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadReportMaricaFaldoneCsv'>
);

type DownloadReportVerbaleCsvMutationVariables = Exact<{
  commessaId: Scalars['String'];
  filters: Scalars['String'];
}>;


type DownloadReportVerbaleCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadReportVerbaleCsv'>
);

type EliminaBarcodeSequenzaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaBarcodeSequenzaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaBarcodeSequenza: (
    { __typename?: 'ResponseEliminaSequenza' }
    & Pick<ResponseEliminaSequenza, 'success' | 'rowAffected'>
  ) }
);

type EliminaCommesseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaCommesseMutation = (
  { __typename?: 'Mutation' }
  & { eliminaCommesse: (
    { __typename?: 'Commesse' }
    & Pick<Commesse, 'id' | 'descrizione' | 'riferimento' | 'committente' | 'cliente' | 'metas' | 'ultimooggetto'>
  ) }
);

type EliminaDdtMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaDdtMutation = (
  { __typename?: 'Mutation' }
  & { eliminaDdt: (
    { __typename?: 'Ddt' }
    & Pick<Ddt, 'id' | 'numero' | 'data' | 'utente' | 'commessa' | 'metas' | 'causaleTrasporto' | 'destinatario' | 'destinatarioLuogo' | 'righe' | 'aspettoEsteriore' | 'numeroColli' | 'pesoKg' | 'inizioTrasportoAMezzo' | 'numeroProggressivo'>
  ) }
);

type EliminaEtichetteBobinaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaEtichetteBobinaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaEtichetteBobina: (
    { __typename?: 'ResponseEtichetteBobina' }
    & Pick<ResponseEtichetteBobina, 'success' | 'rowAffected' | 'error'>
  ) }
);

type EliminaFaldoneMutationVariables = Exact<{
  faldone: Scalars['String'];
}>;


type EliminaFaldoneMutation = (
  { __typename?: 'Mutation' }
  & { eliminaFaldone: (
    { __typename?: 'ResponseDelete' }
    & Pick<ResponseDelete, 'success' | 'message' | 'rowAffected'>
  ) }
);

type EliminaMovimentoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaMovimentoMutation = (
  { __typename?: 'Mutation' }
  & { eliminaMovimento: (
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  ) }
);

type EliminaPraticaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaPraticaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaPratica: (
    { __typename?: 'ResponsePratica' }
    & Pick<ResponsePratica, 'success' | 'rowAffected' | 'error'>
  ) }
);

type EliminaReportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaReportMutation = (
  { __typename?: 'Mutation' }
  & { eliminaReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'dataCreazione' | 'utente' | 'commessaId' | 'descrizione' | 'contenuto' | 'tipologiaReport' | 'querySql'>
  ) }
);

type EliminaSequenzaMutationVariables = Exact<{ [key: string]: never; }>;


type EliminaSequenzaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaSequenza: (
    { __typename?: 'ResponseEliminaSequenza' }
    & Pick<ResponseEliminaSequenza, 'success' | 'rowAffected'>
  ) }
);

type EliminaSocietaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaSocietaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaSocieta: (
    { __typename?: 'Societa' }
    & Pick<Societa, 'id' | 'ragioneSociale' | 'indirizzo' | 'comune' | 'partitaIva' | 'recapito' | 'companyType'>
  ) }
);

type EliminaUtenteMutationVariables = Exact<{
  email: Scalars['String'];
}>;


type EliminaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { eliminaUtente: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
  ) }
);

type EsportaCsvMutationVariables = Exact<{
  commessaId: Scalars['String'];
}>;


type EsportaCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'esportaCsv'>
);

type EsportaCsvSequenzaMutationVariables = Exact<{
  dataInizio: Scalars['Int'];
  dataFine: Scalars['Int'];
}>;


type EsportaCsvSequenzaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'esportaCsvSequenza'>
);

type GeneraPraticheMutationVariables = Exact<{ [key: string]: never; }>;


type GeneraPraticheMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generaPratiche'>
);

type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

type StampaContenutoPalletMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


type StampaContenutoPalletMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stampaContenutoPallet'>
);

type StampaContenutoPalletByPalletMutationVariables = Exact<{
  pallets: Array<Scalars['String']> | Scalars['String'];
}>;


type StampaContenutoPalletByPalletMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stampaContenutoPalletByPallet'>
);

type StampaEtichetteBobinaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type StampaEtichetteBobinaMutation = (
  { __typename?: 'Mutation' }
  & { stampaEtichetteBobina: (
    { __typename?: 'ResponseEtichetteBobina' }
    & Pick<ResponseEtichetteBobina, 'success' | 'rowAffected' | 'error'>
  ) }
);

type StampaReportErroriMutationVariables = Exact<{ [key: string]: never; }>;


type StampaReportErroriMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stampaReportErrori'>
);

type StampaTestaPaccoMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


type StampaTestaPaccoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stampaTestaPacco'>
);

type StampaTestaPaccoByPalletMutationVariables = Exact<{
  pallets: Array<Scalars['String']> | Scalars['String'];
}>;


type StampaTestaPaccoByPalletMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stampaTestaPaccoByPallet'>
);

type AppleSignInQueryVariables = Exact<{
  identityToken: Scalars['String'];
}>;


type AppleSignInQuery = (
  { __typename?: 'Query' }
  & { appleSignIn: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
    ) }
  ) }
);

type AppleSignUpQueryVariables = Exact<{
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


type AppleSignUpQuery = (
  { __typename?: 'Query' }
  & { appleSignUp: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
    ) }
  ) }
);

type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
    ) }
  ) }
);

type CommessaByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type CommessaByIdQuery = (
  { __typename?: 'Query' }
  & { commessaById: (
    { __typename?: 'Commesse' }
    & Pick<Commesse, 'id' | 'descrizione' | 'riferimento' | 'committente' | 'cliente' | 'metas' | 'ultimooggetto'>
  ) }
);

type CommessaFilterQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type CommessaFilterQuery = (
  { __typename?: 'Query' }
  & { commessaFilter: (
    { __typename?: 'Commesse' }
    & Pick<Commesse, 'id' | 'descrizione' | 'riferimento' | 'committente' | 'cliente' | 'metas' | 'ultimooggetto'>
  ) }
);

type DdtByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type DdtByIdQuery = (
  { __typename?: 'Query' }
  & { ddtById: (
    { __typename?: 'Ddt' }
    & Pick<Ddt, 'id' | 'numero' | 'data' | 'utente' | 'commessa' | 'metas' | 'causaleTrasporto' | 'destinatario' | 'destinatarioLuogo' | 'righe' | 'aspettoEsteriore' | 'numeroColli' | 'pesoKg' | 'inizioTrasportoAMezzo' | 'numeroProggressivo'>
  ) }
);

type ElencoBarcodeSequenzaQueryVariables = Exact<{ [key: string]: never; }>;


type ElencoBarcodeSequenzaQuery = (
  { __typename?: 'Query' }
  & { elencoBarcodeSequenza: Array<(
    { __typename?: 'BarcodeSequenza' }
    & Pick<BarcodeSequenza, 'id' | 'dataMovimento' | 'utente' | 'barcode'>
  )> }
);

type ElencoCommesseQueryVariables = Exact<{ [key: string]: never; }>;


type ElencoCommesseQuery = (
  { __typename?: 'Query' }
  & { elencoCommesse: Array<(
    { __typename?: 'Commesse' }
    & Pick<Commesse, 'id' | 'descrizione' | 'riferimento' | 'committente' | 'cliente' | 'metas' | 'ultimooggetto'>
  )> }
);

type ElencoDdtQueryVariables = Exact<{
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
}>;


type ElencoDdtQuery = (
  { __typename?: 'Query' }
  & { elencoDdt: Array<(
    { __typename?: 'Ddt' }
    & Pick<Ddt, 'id' | 'numero' | 'data' | 'utente' | 'commessa' | 'metas' | 'causaleTrasporto' | 'destinatario' | 'destinatarioLuogo' | 'righe' | 'aspettoEsteriore' | 'numeroColli' | 'pesoKg' | 'inizioTrasportoAMezzo' | 'numeroProggressivo'>
  )> }
);

type ElencoEtichetteBobinaQueryVariables = Exact<{ [key: string]: never; }>;


type ElencoEtichetteBobinaQuery = (
  { __typename?: 'Query' }
  & { elencoEtichetteBobina: Array<(
    { __typename?: 'EtichetteBobina' }
    & Pick<EtichetteBobina, 'id' | 'dataCreazione' | 'descrizione' | 'commessa' | 'utente' | 'dal' | 'al' | 'tipologia' | 'zpl' | 'dataArray' | 'width_mm' | 'height_mm' | 'stampata'>
  )> }
);

type ElencoMovimentiQueryVariables = Exact<{
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
}>;


type ElencoMovimentiQuery = (
  { __typename?: 'Query' }
  & { elencoMovimenti: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type ElencoMovimentiBarcodeQueryVariables = Exact<{
  codice: Scalars['String'];
}>;


type ElencoMovimentiBarcodeQuery = (
  { __typename?: 'Query' }
  & { elencoMovimentiBarcode: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type ElencoMovimentiGiornalieriUtenteQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


type ElencoMovimentiGiornalieriUtenteQuery = (
  { __typename?: 'Query' }
  & { elencoMovimentiGiornalieriUtente: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type ElencoMovimentiRicercaQueryVariables = Exact<{
  search: Scalars['String'];
}>;


type ElencoMovimentiRicercaQuery = (
  { __typename?: 'Query' }
  & { elencoMovimentiRicerca: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type ElencoMovimentiRicercaInPeriodoQueryVariables = Exact<{
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  filter: Scalars['String'];
  limit: Scalars['Int'];
  commessaId: Scalars['String'];
}>;


type ElencoMovimentiRicercaInPeriodoQuery = (
  { __typename?: 'Query' }
  & { elencoMovimentiRicercaInPeriodo: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type ElencoMovimentiUtenteQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


type ElencoMovimentiUtenteQuery = (
  { __typename?: 'Query' }
  & { elencoMovimentiUtente: Array<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'id' | 'dataMovimento' | 'utente' | 'tipologiaMovimento' | 'barcodeAllocato' | 'tipologiaBarcodeAllocato' | 'barcodeUbicazione' | 'tipologiaUbicazione' | 'commessa' | 'metas' | 'da' | 'a'>
  )> }
);

type ElencoPalletViewQueryVariables = Exact<{
  filter: Scalars['String'];
  commessaId: Scalars['String'];
}>;


type ElencoPalletViewQuery = (
  { __typename?: 'Query' }
  & { elencoPalletView: Array<(
    { __typename?: 'PalletView' }
    & Pick<PalletView, 'pallet' | 'scatola' | 'faldone' | 'tipologia' | 'anno' | 'numeroFaldoni' | 'numeroPratiche' | 'da' | 'a' | 'metasScatola' | 'metasFaldone' | 'metasPallet'>
  )> }
);

type ElencoPalletsQueryVariables = Exact<{
  commesaIds: Array<Scalars['String']> | Scalars['String'];
}>;


type ElencoPalletsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'elencoPallets'>
);

type ElencoPraticheQueryVariables = Exact<{ [key: string]: never; }>;


type ElencoPraticheQuery = (
  { __typename?: 'Query' }
  & { elencoPratiche: Array<(
    { __typename?: 'Pratica' }
    & Pick<Pratica, 'id' | 'dataPrimoMovimento' | 'dataUltimoMovimento' | 'riferimento' | 'tipologiaRiferimento' | 'barcodeFaldone' | 'tipologiaPratica' | 'scatola' | 'scatolaAnno' | 'pallet' | 'idMovimentoBase' | 'cliente' | 'cliente_committente' | 'commessa'>
  )> }
);

type ElencoPratichePeriodoQueryVariables = Exact<{
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
}>;


type ElencoPratichePeriodoQuery = (
  { __typename?: 'Query' }
  & { elencoPratichePeriodo: Array<(
    { __typename?: 'Pratica' }
    & Pick<Pratica, 'id' | 'dataPrimoMovimento' | 'dataUltimoMovimento' | 'riferimento' | 'tipologiaRiferimento' | 'barcodeFaldone' | 'tipologiaPratica' | 'scatola' | 'scatolaAnno' | 'pallet' | 'idMovimentoBase' | 'cliente' | 'cliente_committente' | 'commessa'>
  )> }
);

type ElencoReportsQueryVariables = Exact<{ [key: string]: never; }>;


type ElencoReportsQuery = (
  { __typename?: 'Query' }
  & { elencoReports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'dataCreazione' | 'utente' | 'commessaId' | 'descrizione' | 'contenuto' | 'tipologiaReport' | 'querySql'>
  )> }
);

type ElencoSocietaQueryVariables = Exact<{ [key: string]: never; }>;


type ElencoSocietaQuery = (
  { __typename?: 'Query' }
  & { elencoSocieta: Array<(
    { __typename?: 'Societa' }
    & Pick<Societa, 'id' | 'ragioneSociale' | 'indirizzo' | 'comune' | 'partitaIva' | 'recapito' | 'companyType'>
  )> }
);

type ElencoStatisticheQueryVariables = Exact<{
  commessaId: Array<Scalars['String']> | Scalars['String'];
}>;


type ElencoStatisticheQuery = (
  { __typename?: 'Query' }
  & { elencoStatistiche: Array<(
    { __typename?: 'Statistiche' }
    & Pick<Statistiche, 'tipologia' | 'conteggio' | 'value'>
  )> }
);

type EtichetteBobinaByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type EtichetteBobinaByIdQuery = (
  { __typename?: 'Query' }
  & { etichetteBobinaById: (
    { __typename?: 'EtichetteBobina' }
    & Pick<EtichetteBobina, 'id' | 'dataCreazione' | 'descrizione' | 'commessa' | 'utente' | 'dal' | 'al' | 'tipologia' | 'zpl' | 'dataArray' | 'width_mm' | 'height_mm' | 'stampata'>
  ) }
);

type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
    ) }
  ) }
);

type LoginQueryVariables = Exact<{
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


type LoginQuery = (
  { __typename?: 'Query' }
  & { login: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
    ) }
  ) }
);

type PingQueryVariables = Exact<{ [key: string]: never; }>;


type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

type ReportByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type ReportByIdQuery = (
  { __typename?: 'Query' }
  & { reportById: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'dataCreazione' | 'utente' | 'commessaId' | 'descrizione' | 'contenuto' | 'tipologiaReport' | 'querySql'>
  )> }
);

type ScaricaReportTestaPaccoQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


type ScaricaReportTestaPaccoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'scaricaReportTestaPacco'>
);

type StampaContenutoPalletByCommessaQueryVariables = Exact<{
  commessaId: Scalars['String'];
}>;


type StampaContenutoPalletByCommessaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stampaContenutoPalletByCommessa'>
);

type StampaVerbaleByCommessaQueryVariables = Exact<{
  commessaId: Scalars['String'];
}>;


type StampaVerbaleByCommessaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stampaVerbaleByCommessa'>
);

type TracciatoCsvPalletQueryVariables = Exact<{
  commessaId: Scalars['String'];
}>;


type TracciatoCsvPalletQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tracciatoCsvPallet'>
);

type UtenteLoggatoQueryVariables = Exact<{ [key: string]: never; }>;


type UtenteLoggatoQuery = (
  { __typename?: 'Query' }
  & { utenteLoggato: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
  ) }
);

type UtentiQueryVariables = Exact<{ [key: string]: never; }>;


type UtentiQuery = (
  { __typename?: 'Query' }
  & { utenti?: Maybe<Array<Maybe<(
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'commessaId'>
  )>>> }
);


 const AggiornaCommesseDocument = gql`
    mutation aggiornaCommesse($input: InputCommesse) {
  aggiornaCommesse(input: $input) {
    id
    descrizione
    riferimento
    committente
    cliente
    metas
    ultimooggetto
  }
}
    `;
 const AggiornaDdtDocument = gql`
    mutation aggiornaDdt($movimento: InputDdt!) {
  aggiornaDdt(movimento: $movimento) {
    id
    numero
    data
    utente
    commessa
    metas
    causaleTrasporto
    destinatario
    destinatarioLuogo
    righe
    aspettoEsteriore
    numeroColli
    pesoKg
    inizioTrasportoAMezzo
    numeroProggressivo
  }
}
    `;
 const AggiornaEtichetteBobinaDocument = gql`
    mutation aggiornaEtichetteBobina($input: InputEtichetteBobina!) {
  aggiornaEtichetteBobina(input: $input) {
    id
    dataCreazione
    descrizione
    commessa
    utente
    dal
    al
    tipologia
    zpl
    dataArray
    width_mm
    height_mm
    stampata
  }
}
    `;
 const AggiornaReportDocument = gql`
    mutation aggiornaReport($input: InputReport!) {
  aggiornaReport(input: $input) {
    id
    dataCreazione
    utente
    commessaId
    descrizione
    contenuto
    tipologiaReport
    querySql
  }
}
    `;
 const AggiornaSocietaDocument = gql`
    mutation aggiornaSocieta($input: InputSocieta!) {
  aggiornaSocieta(input: $input) {
    id
    ragioneSociale
    indirizzo
    comune
    partitaIva
    recapito
    companyType
  }
}
    `;
 const AggiornaUtenteDocument = gql`
    mutation aggiornaUtente($utente: InputAggiornaUtente!) {
  aggiornaUtente(utente: $utente) {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    commessaId
  }
}
    `;
 const CambiaPasswordDocument = gql`
    mutation cambiaPassword($email: String!, $password: String!) {
  cambiaPassword(email: $email, password: $password) {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    commessaId
  }
}
    `;
 const CreaBarcodeSequenzaDocument = gql`
    mutation creaBarcodeSequenza($input: InputBarcodeSequenza!) {
  creaBarcodeSequenza(input: $input) {
    id
    dataMovimento
    utente
    barcode
  }
}
    `;
 const CreaCommesseDocument = gql`
    mutation creaCommesse($input: InputCommesse!) {
  creaCommesse(input: $input) {
    id
    descrizione
    riferimento
    committente
    cliente
    metas
    ultimooggetto
  }
}
    `;
 const CreaDdtDocument = gql`
    mutation creaDdt($movimento: InputDdt!) {
  creaDdt(movimento: $movimento) {
    id
    numero
    data
    utente
    commessa
    metas
    causaleTrasporto
    destinatario
    destinatarioLuogo
    righe
    aspettoEsteriore
    numeroColli
    pesoKg
    inizioTrasportoAMezzo
    numeroProggressivo
  }
}
    `;
 const CreaEtichetteBobinaDocument = gql`
    mutation creaEtichetteBobina($input: InputEtichetteBobina!) {
  creaEtichetteBobina(input: $input) {
    id
    dataCreazione
    descrizione
    commessa
    utente
    dal
    al
    tipologia
    zpl
    dataArray
    width_mm
    height_mm
    stampata
  }
}
    `;
 const CreaMovimentiDocument = gql`
    mutation creaMovimenti($movimento: InputMovimenti!) {
  creaMovimenti(movimento: $movimento) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const CreaMovimentoDocument = gql`
    mutation creaMovimento($movimento: InputMovimento!) {
  creaMovimento(movimento: $movimento) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const CreaPraticaDocument = gql`
    mutation creaPratica($input: InputPratica!) {
  creaPratica(input: $input) {
    id
    dataPrimoMovimento
    dataUltimoMovimento
    riferimento
    tipologiaRiferimento
    barcodeFaldone
    tipologiaPratica
    scatola
    scatolaAnno
    pallet
    idMovimentoBase
    cliente
    cliente_committente
    commessa
  }
}
    `;
 const CreaReportDocument = gql`
    mutation creaReport($input: InputReport!) {
  creaReport(input: $input) {
    id
    dataCreazione
    utente
    commessaId
    descrizione
    contenuto
    tipologiaReport
    querySql
  }
}
    `;
 const CreaSocietaDocument = gql`
    mutation creaSocieta($input: InputSocieta!) {
  creaSocieta(input: $input) {
    id
    ragioneSociale
    indirizzo
    comune
    partitaIva
    recapito
    companyType
  }
}
    `;
 const CreaUtenteDocument = gql`
    mutation creaUtente($utente: InputUtenteConPassword) {
  creaUtente(utente: $utente) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      commessaId
    }
  }
}
    `;
 const DownloadEtichetteBobinaPdfDocument = gql`
    mutation downloadEtichetteBobinaPDF($id: String!) {
  downloadEtichetteBobinaPDF(id: $id) {
    success
    error
    data
  }
}
    `;
 const DownloadReportDocument = gql`
    mutation downloadReport($reportid: String!, $filters: String!) {
  downloadReport(reportid: $reportid, filters: $filters)
}
    `;
 const DownloadReportByTipologiaDocument = gql`
    mutation downloadReportByTipologia($tipologiaReport: String!, $commessaId: String!, $filters: String!) {
  downloadReportByTipologia(
    tipologiaReport: $tipologiaReport
    commessaId: $commessaId
    filters: $filters
  )
}
    `;
 const DownloadReportMaricaCsvDocument = gql`
    mutation downloadReportMaricaCsv($commessaId: String!, $filters: String!) {
  downloadReportMaricaCsv(commessaId: $commessaId, filters: $filters)
}
    `;
 const DownloadReportMaricaDorsoduroCsvDocument = gql`
    mutation downloadReportMaricaDorsoduroCsv($commessaId: String!, $filters: String!) {
  downloadReportMaricaDorsoduroCsv(commessaId: $commessaId, filters: $filters)
}
    `;
 const DownloadReportMaricaFaldoneCsvDocument = gql`
    mutation downloadReportMaricaFaldoneCsv($commessaId: String!, $filters: String!) {
  downloadReportMaricaFaldoneCsv(commessaId: $commessaId, filters: $filters)
}
    `;
 const DownloadReportVerbaleCsvDocument = gql`
    mutation downloadReportVerbaleCsv($commessaId: String!, $filters: String!) {
  downloadReportVerbaleCsv(commessaId: $commessaId, filters: $filters)
}
    `;
 const EliminaBarcodeSequenzaDocument = gql`
    mutation eliminaBarcodeSequenza($id: String!) {
  eliminaBarcodeSequenza(id: $id) {
    success
    rowAffected
  }
}
    `;
 const EliminaCommesseDocument = gql`
    mutation eliminaCommesse($id: String!) {
  eliminaCommesse(id: $id) {
    id
    descrizione
    riferimento
    committente
    cliente
    metas
    ultimooggetto
  }
}
    `;
 const EliminaDdtDocument = gql`
    mutation eliminaDdt($id: String!) {
  eliminaDdt(id: $id) {
    id
    numero
    data
    utente
    commessa
    metas
    causaleTrasporto
    destinatario
    destinatarioLuogo
    righe
    aspettoEsteriore
    numeroColli
    pesoKg
    inizioTrasportoAMezzo
    numeroProggressivo
  }
}
    `;
 const EliminaEtichetteBobinaDocument = gql`
    mutation eliminaEtichetteBobina($id: String!) {
  eliminaEtichetteBobina(id: $id) {
    success
    rowAffected
    error
  }
}
    `;
 const EliminaFaldoneDocument = gql`
    mutation eliminaFaldone($faldone: String!) {
  eliminaFaldone(faldone: $faldone) {
    success
    message
    rowAffected
  }
}
    `;
 const EliminaMovimentoDocument = gql`
    mutation eliminaMovimento($id: String!) {
  eliminaMovimento(id: $id) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const EliminaPraticaDocument = gql`
    mutation eliminaPratica($id: String!) {
  eliminaPratica(id: $id) {
    success
    rowAffected
    error
  }
}
    `;
 const EliminaReportDocument = gql`
    mutation eliminaReport($id: String!) {
  eliminaReport(id: $id) {
    id
    dataCreazione
    utente
    commessaId
    descrizione
    contenuto
    tipologiaReport
    querySql
  }
}
    `;
 const EliminaSequenzaDocument = gql`
    mutation eliminaSequenza {
  eliminaSequenza {
    success
    rowAffected
  }
}
    `;
 const EliminaSocietaDocument = gql`
    mutation eliminaSocieta($id: String!) {
  eliminaSocieta(id: $id) {
    id
    ragioneSociale
    indirizzo
    comune
    partitaIva
    recapito
    companyType
  }
}
    `;
 const EliminaUtenteDocument = gql`
    mutation eliminaUtente($email: String!) {
  eliminaUtente(email: $email) {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    commessaId
  }
}
    `;
 const EsportaCsvDocument = gql`
    mutation esportaCsv($commessaId: String!) {
  esportaCsv(commessaId: $commessaId)
}
    `;
 const EsportaCsvSequenzaDocument = gql`
    mutation esportaCsvSequenza($dataInizio: Int!, $dataFine: Int!) {
  esportaCsvSequenza(dataInizio: $dataInizio, dataFine: $dataFine)
}
    `;
 const GeneraPraticheDocument = gql`
    mutation generaPratiche {
  generaPratiche
}
    `;
 const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
 const StampaContenutoPalletDocument = gql`
    mutation stampaContenutoPallet($ids: [String!]!) {
  stampaContenutoPallet(ids: $ids)
}
    `;
 const StampaContenutoPalletByPalletDocument = gql`
    mutation stampaContenutoPalletByPallet($pallets: [String!]!) {
  stampaContenutoPalletByPallet(pallets: $pallets)
}
    `;
 const StampaEtichetteBobinaDocument = gql`
    mutation stampaEtichetteBobina($id: String!) {
  stampaEtichetteBobina(id: $id) {
    success
    rowAffected
    error
  }
}
    `;
 const StampaReportErroriDocument = gql`
    mutation stampaReportErrori {
  stampaReportErrori
}
    `;
 const StampaTestaPaccoDocument = gql`
    mutation stampaTestaPacco($ids: [String!]!) {
  stampaTestaPacco(ids: $ids)
}
    `;
 const StampaTestaPaccoByPalletDocument = gql`
    mutation stampaTestaPaccoByPallet($pallets: [String!]!) {
  stampaTestaPaccoByPallet(pallets: $pallets)
}
    `;
 const AppleSignInDocument = gql`
    query appleSignIn($identityToken: String!) {
  appleSignIn(identityToken: $identityToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      commessaId
    }
  }
}
    `;
 const AppleSignUpDocument = gql`
    query appleSignUp($identityToken: String!, $firstName: String!, $lastName: String!) {
  appleSignUp(
    identityToken: $identityToken
    firstName: $firstName
    lastName: $lastName
  ) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      commessaId
    }
  }
}
    `;
 const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      commessaId
    }
  }
}
    `;
 const CommessaByIdDocument = gql`
    query commessaById($id: String!) {
  commessaById(id: $id) {
    id
    descrizione
    riferimento
    committente
    cliente
    metas
    ultimooggetto
  }
}
    `;
 const CommessaFilterDocument = gql`
    query commessaFilter($id: String!) {
  commessaFilter(id: $id) {
    id
    descrizione
    riferimento
    committente
    cliente
    metas
    ultimooggetto
  }
}
    `;
 const DdtByIdDocument = gql`
    query ddtById($id: String!) {
  ddtById(id: $id) {
    id
    numero
    data
    utente
    commessa
    metas
    causaleTrasporto
    destinatario
    destinatarioLuogo
    righe
    aspettoEsteriore
    numeroColli
    pesoKg
    inizioTrasportoAMezzo
    numeroProggressivo
  }
}
    `;
 const ElencoBarcodeSequenzaDocument = gql`
    query elencoBarcodeSequenza {
  elencoBarcodeSequenza {
    id
    dataMovimento
    utente
    barcode
  }
}
    `;
 const ElencoCommesseDocument = gql`
    query elencoCommesse {
  elencoCommesse {
    id
    descrizione
    riferimento
    committente
    cliente
    metas
    ultimooggetto
  }
}
    `;
 const ElencoDdtDocument = gql`
    query elencoDdt($startDate: Int!, $endDate: Int!) {
  elencoDdt(startDate: $startDate, endDate: $endDate) {
    id
    numero
    data
    utente
    commessa
    metas
    causaleTrasporto
    destinatario
    destinatarioLuogo
    righe
    aspettoEsteriore
    numeroColli
    pesoKg
    inizioTrasportoAMezzo
    numeroProggressivo
  }
}
    `;
 const ElencoEtichetteBobinaDocument = gql`
    query elencoEtichetteBobina {
  elencoEtichetteBobina {
    id
    dataCreazione
    descrizione
    commessa
    utente
    dal
    al
    tipologia
    zpl
    dataArray
    width_mm
    height_mm
    stampata
  }
}
    `;
 const ElencoMovimentiDocument = gql`
    query elencoMovimenti($startDate: Int!, $endDate: Int!) {
  elencoMovimenti(startDate: $startDate, endDate: $endDate) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const ElencoMovimentiBarcodeDocument = gql`
    query elencoMovimentiBarcode($codice: String!) {
  elencoMovimentiBarcode(codice: $codice) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const ElencoMovimentiGiornalieriUtenteDocument = gql`
    query elencoMovimentiGiornalieriUtente($limit: Int!) {
  elencoMovimentiGiornalieriUtente(limit: $limit) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const ElencoMovimentiRicercaDocument = gql`
    query elencoMovimentiRicerca($search: String!) {
  elencoMovimentiRicerca(search: $search) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const ElencoMovimentiRicercaInPeriodoDocument = gql`
    query elencoMovimentiRicercaInPeriodo($startDate: Int!, $endDate: Int!, $filter: String!, $limit: Int!, $commessaId: String!) {
  elencoMovimentiRicercaInPeriodo(
    startDate: $startDate
    endDate: $endDate
    filter: $filter
    limit: $limit
    commessaId: $commessaId
  ) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const ElencoMovimentiUtenteDocument = gql`
    query elencoMovimentiUtente($limit: Int!) {
  elencoMovimentiUtente(limit: $limit) {
    id
    dataMovimento
    utente
    tipologiaMovimento
    barcodeAllocato
    tipologiaBarcodeAllocato
    barcodeUbicazione
    tipologiaUbicazione
    commessa
    metas
    da
    a
  }
}
    `;
 const ElencoPalletViewDocument = gql`
    query elencoPalletView($filter: String!, $commessaId: String!) {
  elencoPalletView(filter: $filter, commessaId: $commessaId) {
    pallet
    scatola
    faldone
    tipologia
    anno
    numeroFaldoni
    numeroPratiche
    da
    a
    metasScatola
    metasFaldone
    metasPallet
  }
}
    `;
 const ElencoPalletsDocument = gql`
    query elencoPallets($commesaIds: [String!]!) {
  elencoPallets(commesaIds: $commesaIds)
}
    `;
 const ElencoPraticheDocument = gql`
    query elencoPratiche {
  elencoPratiche {
    id
    dataPrimoMovimento
    dataUltimoMovimento
    riferimento
    tipologiaRiferimento
    barcodeFaldone
    tipologiaPratica
    scatola
    scatolaAnno
    pallet
    idMovimentoBase
    cliente
    cliente_committente
    commessa
  }
}
    `;
 const ElencoPratichePeriodoDocument = gql`
    query elencoPratichePeriodo($startDate: Int!, $endDate: Int!) {
  elencoPratichePeriodo(startDate: $startDate, endDate: $endDate) {
    id
    dataPrimoMovimento
    dataUltimoMovimento
    riferimento
    tipologiaRiferimento
    barcodeFaldone
    tipologiaPratica
    scatola
    scatolaAnno
    pallet
    idMovimentoBase
    cliente
    cliente_committente
    commessa
  }
}
    `;
 const ElencoReportsDocument = gql`
    query elencoReports {
  elencoReports {
    id
    dataCreazione
    utente
    commessaId
    descrizione
    contenuto
    tipologiaReport
    querySql
  }
}
    `;
 const ElencoSocietaDocument = gql`
    query elencoSocieta {
  elencoSocieta {
    id
    ragioneSociale
    indirizzo
    comune
    partitaIva
    recapito
    companyType
  }
}
    `;
 const ElencoStatisticheDocument = gql`
    query elencoStatistiche($commessaId: [String!]!) {
  elencoStatistiche(commessaId: $commessaId) {
    tipologia
    conteggio
    value
  }
}
    `;
 const EtichetteBobinaByIdDocument = gql`
    query etichetteBobinaById($id: String!) {
  etichetteBobinaById(id: $id) {
    id
    dataCreazione
    descrizione
    commessa
    utente
    dal
    al
    tipologia
    zpl
    dataArray
    width_mm
    height_mm
    stampata
  }
}
    `;
 const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      commessaId
    }
  }
}
    `;
 const LoginDocument = gql`
    query login($identityToken: String!, $firstName: String!, $lastName: String!) {
  login(identityToken: $identityToken, firstName: $firstName, lastName: $lastName) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      commessaId
    }
  }
}
    `;
 const PingDocument = gql`
    query ping {
  ping
}
    `;
 const ReportByIdDocument = gql`
    query reportById($id: String!) {
  reportById(id: $id) {
    id
    dataCreazione
    utente
    commessaId
    descrizione
    contenuto
    tipologiaReport
    querySql
  }
}
    `;
 const ScaricaReportTestaPaccoDocument = gql`
    query scaricaReportTestaPacco($ids: [String!]!) {
  scaricaReportTestaPacco(ids: $ids)
}
    `;
 const StampaContenutoPalletByCommessaDocument = gql`
    query stampaContenutoPalletByCommessa($commessaId: String!) {
  stampaContenutoPalletByCommessa(commessaId: $commessaId)
}
    `;
 const StampaVerbaleByCommessaDocument = gql`
    query stampaVerbaleByCommessa($commessaId: String!) {
  stampaVerbaleByCommessa(commessaId: $commessaId)
}
    `;
 const TracciatoCsvPalletDocument = gql`
    query tracciatoCsvPallet($commessaId: String!) {
  tracciatoCsvPallet(commessaId: $commessaId)
}
    `;
 const UtenteLoggatoDocument = gql`
    query utenteLoggato {
  utenteLoggato {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    commessaId
  }
}
    `;
 const UtentiDocument = gql`
    query utenti {
  utenti {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    commessaId
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    aggiornaCommesse(variables?: AggiornaCommesseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaCommesseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AggiornaCommesseMutation>(AggiornaCommesseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggiornaCommesse');
    },
    aggiornaDdt(variables: AggiornaDdtMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaDdtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AggiornaDdtMutation>(AggiornaDdtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggiornaDdt');
    },
    aggiornaEtichetteBobina(variables: AggiornaEtichetteBobinaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaEtichetteBobinaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AggiornaEtichetteBobinaMutation>(AggiornaEtichetteBobinaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggiornaEtichetteBobina');
    },
    aggiornaReport(variables: AggiornaReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaReportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AggiornaReportMutation>(AggiornaReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggiornaReport');
    },
    aggiornaSocieta(variables: AggiornaSocietaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaSocietaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AggiornaSocietaMutation>(AggiornaSocietaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggiornaSocieta');
    },
    aggiornaUtente(variables: AggiornaUtenteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaUtenteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AggiornaUtenteMutation>(AggiornaUtenteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'aggiornaUtente');
    },
    cambiaPassword(variables: CambiaPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CambiaPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CambiaPasswordMutation>(CambiaPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cambiaPassword');
    },
    creaBarcodeSequenza(variables: CreaBarcodeSequenzaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaBarcodeSequenzaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaBarcodeSequenzaMutation>(CreaBarcodeSequenzaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaBarcodeSequenza');
    },
    creaCommesse(variables: CreaCommesseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaCommesseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaCommesseMutation>(CreaCommesseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaCommesse');
    },
    creaDdt(variables: CreaDdtMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaDdtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaDdtMutation>(CreaDdtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaDdt');
    },
    creaEtichetteBobina(variables: CreaEtichetteBobinaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaEtichetteBobinaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaEtichetteBobinaMutation>(CreaEtichetteBobinaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaEtichetteBobina');
    },
    creaMovimenti(variables: CreaMovimentiMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaMovimentiMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaMovimentiMutation>(CreaMovimentiDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaMovimenti');
    },
    creaMovimento(variables: CreaMovimentoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaMovimentoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaMovimentoMutation>(CreaMovimentoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaMovimento');
    },
    creaPratica(variables: CreaPraticaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaPraticaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaPraticaMutation>(CreaPraticaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaPratica');
    },
    creaReport(variables: CreaReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaReportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaReportMutation>(CreaReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaReport');
    },
    creaSocieta(variables: CreaSocietaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaSocietaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaSocietaMutation>(CreaSocietaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaSocieta');
    },
    creaUtente(variables?: CreaUtenteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaUtenteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreaUtenteMutation>(CreaUtenteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'creaUtente');
    },
    downloadEtichetteBobinaPDF(variables: DownloadEtichetteBobinaPdfMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadEtichetteBobinaPdfMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadEtichetteBobinaPdfMutation>(DownloadEtichetteBobinaPdfDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadEtichetteBobinaPDF');
    },
    downloadReport(variables: DownloadReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadReportMutation>(DownloadReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadReport');
    },
    downloadReportByTipologia(variables: DownloadReportByTipologiaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportByTipologiaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadReportByTipologiaMutation>(DownloadReportByTipologiaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadReportByTipologia');
    },
    downloadReportMaricaCsv(variables: DownloadReportMaricaCsvMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportMaricaCsvMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadReportMaricaCsvMutation>(DownloadReportMaricaCsvDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadReportMaricaCsv');
    },
    downloadReportMaricaDorsoduroCsv(variables: DownloadReportMaricaDorsoduroCsvMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportMaricaDorsoduroCsvMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadReportMaricaDorsoduroCsvMutation>(DownloadReportMaricaDorsoduroCsvDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadReportMaricaDorsoduroCsv');
    },
    downloadReportMaricaFaldoneCsv(variables: DownloadReportMaricaFaldoneCsvMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportMaricaFaldoneCsvMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadReportMaricaFaldoneCsvMutation>(DownloadReportMaricaFaldoneCsvDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadReportMaricaFaldoneCsv');
    },
    downloadReportVerbaleCsv(variables: DownloadReportVerbaleCsvMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportVerbaleCsvMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DownloadReportVerbaleCsvMutation>(DownloadReportVerbaleCsvDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'downloadReportVerbaleCsv');
    },
    eliminaBarcodeSequenza(variables: EliminaBarcodeSequenzaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaBarcodeSequenzaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaBarcodeSequenzaMutation>(EliminaBarcodeSequenzaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaBarcodeSequenza');
    },
    eliminaCommesse(variables: EliminaCommesseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaCommesseMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaCommesseMutation>(EliminaCommesseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaCommesse');
    },
    eliminaDdt(variables: EliminaDdtMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaDdtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaDdtMutation>(EliminaDdtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaDdt');
    },
    eliminaEtichetteBobina(variables: EliminaEtichetteBobinaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaEtichetteBobinaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaEtichetteBobinaMutation>(EliminaEtichetteBobinaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaEtichetteBobina');
    },
    eliminaFaldone(variables: EliminaFaldoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaFaldoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaFaldoneMutation>(EliminaFaldoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaFaldone');
    },
    eliminaMovimento(variables: EliminaMovimentoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaMovimentoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaMovimentoMutation>(EliminaMovimentoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaMovimento');
    },
    eliminaPratica(variables: EliminaPraticaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaPraticaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaPraticaMutation>(EliminaPraticaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaPratica');
    },
    eliminaReport(variables: EliminaReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaReportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaReportMutation>(EliminaReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaReport');
    },
    eliminaSequenza(variables?: EliminaSequenzaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaSequenzaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaSequenzaMutation>(EliminaSequenzaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaSequenza');
    },
    eliminaSocieta(variables: EliminaSocietaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaSocietaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaSocietaMutation>(EliminaSocietaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaSocieta');
    },
    eliminaUtente(variables: EliminaUtenteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EliminaUtenteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EliminaUtenteMutation>(EliminaUtenteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eliminaUtente');
    },
    esportaCsv(variables: EsportaCsvMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EsportaCsvMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EsportaCsvMutation>(EsportaCsvDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'esportaCsv');
    },
    esportaCsvSequenza(variables: EsportaCsvSequenzaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EsportaCsvSequenzaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EsportaCsvSequenzaMutation>(EsportaCsvSequenzaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'esportaCsvSequenza');
    },
    generaPratiche(variables?: GeneraPraticheMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GeneraPraticheMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GeneraPraticheMutation>(GeneraPraticheDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generaPratiche');
    },
    pingMutation(variables?: PingMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingMutationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PingMutationMutation>(PingMutationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pingMutation');
    },
    stampaContenutoPallet(variables: StampaContenutoPalletMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaContenutoPalletMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaContenutoPalletMutation>(StampaContenutoPalletDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaContenutoPallet');
    },
    stampaContenutoPalletByPallet(variables: StampaContenutoPalletByPalletMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaContenutoPalletByPalletMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaContenutoPalletByPalletMutation>(StampaContenutoPalletByPalletDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaContenutoPalletByPallet');
    },
    stampaEtichetteBobina(variables: StampaEtichetteBobinaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaEtichetteBobinaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaEtichetteBobinaMutation>(StampaEtichetteBobinaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaEtichetteBobina');
    },
    stampaReportErrori(variables?: StampaReportErroriMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaReportErroriMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaReportErroriMutation>(StampaReportErroriDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaReportErrori');
    },
    stampaTestaPacco(variables: StampaTestaPaccoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaTestaPaccoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaTestaPaccoMutation>(StampaTestaPaccoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaTestaPacco');
    },
    stampaTestaPaccoByPallet(variables: StampaTestaPaccoByPalletMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaTestaPaccoByPalletMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaTestaPaccoByPalletMutation>(StampaTestaPaccoByPalletDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaTestaPaccoByPallet');
    },
    appleSignIn(variables: AppleSignInQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AppleSignInQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AppleSignInQuery>(AppleSignInDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'appleSignIn');
    },
    appleSignUp(variables: AppleSignUpQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AppleSignUpQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AppleSignUpQuery>(AppleSignUpDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'appleSignUp');
    },
    basicLogin(variables: BasicLoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BasicLoginQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BasicLoginQuery>(BasicLoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'basicLogin');
    },
    commessaById(variables: CommessaByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CommessaByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CommessaByIdQuery>(CommessaByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'commessaById');
    },
    commessaFilter(variables: CommessaFilterQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CommessaFilterQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CommessaFilterQuery>(CommessaFilterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'commessaFilter');
    },
    ddtById(variables: DdtByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DdtByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DdtByIdQuery>(DdtByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ddtById');
    },
    elencoBarcodeSequenza(variables?: ElencoBarcodeSequenzaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoBarcodeSequenzaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoBarcodeSequenzaQuery>(ElencoBarcodeSequenzaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoBarcodeSequenza');
    },
    elencoCommesse(variables?: ElencoCommesseQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoCommesseQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoCommesseQuery>(ElencoCommesseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoCommesse');
    },
    elencoDdt(variables: ElencoDdtQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoDdtQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoDdtQuery>(ElencoDdtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoDdt');
    },
    elencoEtichetteBobina(variables?: ElencoEtichetteBobinaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoEtichetteBobinaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoEtichetteBobinaQuery>(ElencoEtichetteBobinaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoEtichetteBobina');
    },
    elencoMovimenti(variables: ElencoMovimentiQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoMovimentiQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoMovimentiQuery>(ElencoMovimentiDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoMovimenti');
    },
    elencoMovimentiBarcode(variables: ElencoMovimentiBarcodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoMovimentiBarcodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoMovimentiBarcodeQuery>(ElencoMovimentiBarcodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoMovimentiBarcode');
    },
    elencoMovimentiGiornalieriUtente(variables: ElencoMovimentiGiornalieriUtenteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoMovimentiGiornalieriUtenteQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoMovimentiGiornalieriUtenteQuery>(ElencoMovimentiGiornalieriUtenteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoMovimentiGiornalieriUtente');
    },
    elencoMovimentiRicerca(variables: ElencoMovimentiRicercaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoMovimentiRicercaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoMovimentiRicercaQuery>(ElencoMovimentiRicercaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoMovimentiRicerca');
    },
    elencoMovimentiRicercaInPeriodo(variables: ElencoMovimentiRicercaInPeriodoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoMovimentiRicercaInPeriodoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoMovimentiRicercaInPeriodoQuery>(ElencoMovimentiRicercaInPeriodoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoMovimentiRicercaInPeriodo');
    },
    elencoMovimentiUtente(variables: ElencoMovimentiUtenteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoMovimentiUtenteQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoMovimentiUtenteQuery>(ElencoMovimentiUtenteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoMovimentiUtente');
    },
    elencoPalletView(variables: ElencoPalletViewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoPalletViewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoPalletViewQuery>(ElencoPalletViewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoPalletView');
    },
    elencoPallets(variables: ElencoPalletsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoPalletsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoPalletsQuery>(ElencoPalletsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoPallets');
    },
    elencoPratiche(variables?: ElencoPraticheQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoPraticheQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoPraticheQuery>(ElencoPraticheDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoPratiche');
    },
    elencoPratichePeriodo(variables: ElencoPratichePeriodoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoPratichePeriodoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoPratichePeriodoQuery>(ElencoPratichePeriodoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoPratichePeriodo');
    },
    elencoReports(variables?: ElencoReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoReportsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoReportsQuery>(ElencoReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoReports');
    },
    elencoSocieta(variables?: ElencoSocietaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoSocietaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoSocietaQuery>(ElencoSocietaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoSocieta');
    },
    elencoStatistiche(variables: ElencoStatisticheQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ElencoStatisticheQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ElencoStatisticheQuery>(ElencoStatisticheDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'elencoStatistiche');
    },
    etichetteBobinaById(variables: EtichetteBobinaByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EtichetteBobinaByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EtichetteBobinaByIdQuery>(EtichetteBobinaByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'etichetteBobinaById');
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewAccessTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetNewAccessTokenQuery>(GetNewAccessTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getNewAccessToken');
    },
    login(variables: LoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginQuery>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'login');
    },
    ping(variables?: PingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PingQuery>(PingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ping');
    },
    reportById(variables: ReportByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReportByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReportByIdQuery>(ReportByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'reportById');
    },
    scaricaReportTestaPacco(variables: ScaricaReportTestaPaccoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ScaricaReportTestaPaccoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ScaricaReportTestaPaccoQuery>(ScaricaReportTestaPaccoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'scaricaReportTestaPacco');
    },
    stampaContenutoPalletByCommessa(variables: StampaContenutoPalletByCommessaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaContenutoPalletByCommessaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaContenutoPalletByCommessaQuery>(StampaContenutoPalletByCommessaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaContenutoPalletByCommessa');
    },
    stampaVerbaleByCommessa(variables: StampaVerbaleByCommessaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StampaVerbaleByCommessaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StampaVerbaleByCommessaQuery>(StampaVerbaleByCommessaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stampaVerbaleByCommessa');
    },
    tracciatoCsvPallet(variables: TracciatoCsvPalletQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TracciatoCsvPalletQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TracciatoCsvPalletQuery>(TracciatoCsvPalletDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'tracciatoCsvPallet');
    },
    utenteLoggato(variables?: UtenteLoggatoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UtenteLoggatoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UtenteLoggatoQuery>(UtenteLoggatoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'utenteLoggato');
    },
    utenti(variables?: UtentiQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UtentiQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UtentiQuery>(UtentiDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'utenti');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
import {takeLatest, select, put} from 'redux-saga/effects';
import GraphqlClient from "../client/graphql.client";
import * as actionSocieta from "../pages/societa/redux/societa.actions";
import * as actionCommesse from "../pages/commesse/redux/commesse.actions";

function* exampleSaga(action) {
    console.log("USER LOGGED SAGA", "console log fired with redux-saga")

    const commesse = yield  GraphqlClient.elencoCommesse()
    yield put(actionCommesse.readedAll(commesse.elencoCommesse))

    const societa = yield  GraphqlClient.elencoSocieta()
    yield put(actionSocieta.readedAll(societa.elencoSocieta))

}


function* mySaga() {
    yield takeLatest('AUTH_LOGGED', exampleSaga);

}

export default mySaga;

// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, Row, Col, Card} from "reactstrap";
import React, {FunctionComponent, useState, useEffect, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created, updated} from "../redux/ddt.actions";
import GraphqlClient from "../../../client/graphql.client";
import ClassNames from 'classnames';

import {TextInputHook} from "../../../hooks/text_input.hook";
import Select from "react-select";
import {useSelector} from 'react-redux'
import DdtMetasComponent from "./ddt.metas.component"

const DdtCreateComponent: FunctionComponent = (props: any) => {

    const input_numero = TextInputHook(props?.data?.numero);                                    //: String!
    const input_data = TextInputHook(props?.data?.data);                                  //: Int!
    const input_utente = TextInputHook(props?.data?.utente);                                    //: String!
    const input_causaleTrasporto = TextInputHook(props?.data?.causaleTrasporto);                                  //:String!
    const input_aspettoEsteriore = TextInputHook(props?.data?.aspettoEsteriore);                                  //: String!
    const input_numeroColli = TextInputHook(props?.data?.numeroColli);                                   //: Int!,
    const input_pesoKg = TextInputHook(props?.data?.pesoKg);                                    //: Float!,
    const input_inizioTrasportoAMezzo = TextInputHook(props?.data?.inizioTrasportoAMezzo);                                 //: String! #MITTENTE/DESTINATARIO
    const input_numeroProggressivo = TextInputHook(props?.data?.numeroProggressivo);                                    //:String! #art.3 comma 2 dpr 10 novembre 1997


    const [righe, setrighe] = useState(props?.data?.righe);
    const [commessa, setcommessa] = useState(props?.data?.commessa);
    const [metas, setMetas] = useState(props?.data?.metas);
    const [destinatario, setdestinatario] = useState(props?.data?.destinatario);
    const [destinatarioLuogo, setdestinatarioLuogo] = useState(props?.data?.destinatarioLuogo);

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const elencoCommesse = useSelector(state => state.commesse.data)

    const elencoSocieta = useSelector(state => state.societa.data)

    const commesseMetasComponentRef = useRef(metas);

    const salva = async () => {
        let metas = commesseMetasComponentRef.current.getMetas();
        metas = metas ? metas : []
        try {
            setLoading(true);
            const movimento = {
                id: "",
                numero: input_numero.getValue(),
                data: input_data.getValue(),
                utente: input_utente.getValue(),
                commessa: JSON.stringify(commessa),
                metas: JSON.stringify({}),
                causaleTrasporto: input_causaleTrasporto.getValue(),
                destinatario: JSON.stringify(destinatario),
                destinatarioLuogo: JSON.stringify(destinatarioLuogo),
                righe: JSON.stringify({}),
                aspettoEsteriore: input_aspettoEsteriore.getValue(),
                numeroColli: input_numeroColli.getValue(),
                pesoKg: input_pesoKg.getValue(),
                inizioTrasportoAMezzo: input_inizioTrasportoAMezzo.getValue(),
                numeroProggressivo: input_numeroProggressivo.getValue(),
            }

            const res = await GraphqlClient.creaDdt({movimento})
            console.log(res)
            store.dispatch(created(res.creaDdt)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName={ClassNames("modal-black", 'extra-large-sparkle-modal')}
            isOpen={true}
            toggle={props.toggle}
        >
            <ModalBody>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="modal-header justify-content-center">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={props.toggle}
                            >
                                <i className="tim-icons icon-simple-remove"/>
                            </button>
                            <h6 className="title title-up">Nuova DDT</h6>
                        </div>

                    </Col>
                    <Col xs={12} md={6} style={{paddingLeft: 30, paddingTop: 40}}>
                        <Card>
                            <h6 className="title title-up modal-header justify-content-center">Informazioni base</h6>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_data">Data</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_data.input}
                                        name="input_data"
                                        id="input_data"
                                        placeholder="Inserire data DDT"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_numero">Numero</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_numero.input}
                                        name="input_numero"
                                        id="input_numero"
                                        placeholder="Inserire numero DDT"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_numero">Commessa</Label>
                                </Col>
                                <Col xs={8}>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={commessa}
                                        getOptionLabel={(item) => item.descrizione}
                                        onChange={value =>
                                            setcommessa(value)
                                        }
                                        options={elencoCommesse}
                                        placeholder="Seleziona commessa"
                                    />
                                </Col>
                            </Row>

                        </Card>
                        <Card>
                            <h6 className="title title-up modal-header justify-content-center">Informazioni
                                aggiuntive</h6>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_numero">Causale trasporto</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_causaleTrasporto.input}
                                        name="input_causaleTrasporto"
                                        id="input_causaleTrasporto"
                                        placeholder="Inserire causale trasporto"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_aspettoEsteriore">Aspetto esteriore</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_aspettoEsteriore.input}
                                        name="input_aspettoEsteriore"
                                        id="input_aspettoEsteriore"
                                        placeholder="Scatole/Bancali/ecc.."
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_numero">Numero colli</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_numeroColli.input}
                                        name="input_numeroColli"
                                        id="input_numeroColli"
                                        placeholder="Inserire numero colli"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_pesoKg">Peso in Kg</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_pesoKg.input}
                                        name="input_pesoKg"
                                        id="input_pesoKg"
                                        placeholder="Inserire peso complessivo in Kg del carico"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_inizioTrasportoAMezzo">Trasporto a mezzo</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_inizioTrasportoAMezzo.input}
                                        name="input_inizioTrasportoAMezzo"
                                        id="input_inizioTrasportoAMezzo"
                                        placeholder="Destinatario/mittente"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_numero">Numero progressivo</Label>
                                </Col>
                                <Col xs={8}>
                                    <Input
                                        {...input_numeroProggressivo.input}
                                        name="input_numeroProggressivo"
                                        id="input_numeroProggressivo"
                                        placeholder="input_numeroProggressivo"
                                    />
                                </Col>
                            </Row>


                        </Card>
                    </Col>
                    <Col xs={12} md={6} style={{paddingRight: 30, paddingTop: 40}}>
                        <Card>
                            <h6 className="title title-up modal-header justify-content-center">Destinazione merce</h6>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_destinatario">Destinatario</Label>
                                </Col>
                                <Col xs={8}>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={destinatario}
                                        getOptionLabel={(item) => item.ragioneSociale}
                                        onChange={value =>
                                            setdestinatario(value)
                                        }
                                        options={elencoSocieta}
                                        placeholder="Seleziona destinatario"
                                    />
                                </Col>
                            </Row>
                            <Row style={{padding: 10}}>
                                <Col xs={4}>
                                    <Label for="input_numero">Destinatario luogo</Label>
                                </Col>
                                <Col xs={8}>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={destinatarioLuogo}
                                        getOptionLabel={(item) => item.ragioneSociale}
                                        onChange={value =>
                                            setdestinatarioLuogo(value)
                                        }
                                        options={elencoSocieta}
                                        placeholder="Seleziona luogo destinazione"
                                    />
                                </Col>
                            </Row>

                        </Card>
                        <Card>
                            <FormGroup>
                                <DdtMetasComponent
                                    ref={commesseMetasComponentRef}
                                    metas={metas}>
                                </DdtMetasComponent>
                            </FormGroup>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    )
        ;

}

export default DdtCreateComponent;

import React, {Component} from 'react';
import {
    Button,
    FormGroup,
    Input,
    Label,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardText,
    Row,
    Col
} from "reactstrap";
import Select from "react-select";
import * as _ from 'lodash';
import classnames from 'classnames';
import {TextInputHook} from "../../../hooks/text_input.hook";
import {meters2ScreenPixels} from "google-map-react";
import TagsInput from "react-tagsinput";
import {connect} from "react-redux";
import {get} from 'lodash';

const options = [
    {
        value: "",
        description: "Tipologia",
        isDisabled: true
    },
    {
        value: "string",
        description: "Stringa",
    },
    {
        value: "select",
        description: "Select",
    },
];

class CommesseMetasComponent extends Component {


    constructor(props) {

        super(props);

        let count = 0;
        let max = _.maxBy(_.get(this.props, "metas", []), 'index');

        if (max) count = max.index;

        this.state = {
            metas: props.metas,
            activeTab: 0,
            count
        };

    
    }


    toggle = (tab) => {
        if (this.state.activeTab !== tab) this.setState({activeTab: tab})
    }

    getMetas = () => this.state.metas;

    removeItemFromList = (index) => {
        let metas = this.state.metas
        metas[this.state.activeTab].list = metas[this.state.activeTab].list.filter((el) => el.index !== index)
        this.setState({metas})
    };

    addNewMeta = (e) => {
        e.preventDefault();
        let metas = this.state.metas

        metas[this.state.activeTab].list = _.orderBy([...metas[this.state.activeTab].list, {
            index: metas[this.state.activeTab].list.length + 1,
            fieldName: null,
            fieldType: null,
            fieldExtra: null
        }])
        metas[this.state.activeTab].count = metas[this.state.activeTab].count++

        this.setState({metas})
    };

    addNewTab = () => {
        const newTabId = this.state.metas ? this.state.metas.length + 1 : 1
        const oldMetas = this.state.metas ? this.state.metas : []
        let metas = [...oldMetas, {
            eventToApply: "",
            regEx: "",
            list: [],
            description: "NUOVO",
            barcodeMetasSeparator: "",
            barcodeMetasOrderWithSeparator: "",
        },]

        this.setState({metas, activeTab: newTabId})
    };

    removeItemFromList = (index) => {
        let metas = this.state.metas
        metas[this.state.activeTab].list = metas[this.state.activeTab].list.filter((el) => el.index !== index)
        this.setState({metas})
    };

    deleteTab = (tabId) => {
        let metas = this.state.metas
        metas = metas.filter((el, index) => index !== tabId)
        this.setState({metas})
    };


    updateFieldType = (index, value) => {
        let metas = this.state.metas;


        const preUpdate = metas[this.state.activeTab].list.filter((el) => el.index === index)[0];
        metas[this.state.activeTab].list = _.orderBy([...metas[this.state.activeTab].list.filter((el) => el.index !== index), {
            ...preUpdate,
            fieldType: value
        }], 'index')

        console.log('@@@@@@@@@', {index, value, metas, activeTab: this.state.activeTab})
        this.setState({metas})

    };

    updateFieldName = (index, value) => {
        let metas = this.state.metas
        const preUpdate = metas[this.state.activeTab].list.filter((el) => el.index === index)[0];
        metas[this.state.activeTab].list = _.orderBy([...metas[this.state.activeTab].list.filter((el) => el.index !== index), {
            ...preUpdate,
            fieldName: value
        }], 'index')
        this.setState({metas})
    };

    updateField = (indexField, field, value) => {
        let meta = this.state.metas[this.state.activeTab];
        let objectToUpdate = meta.list[indexField];

        objectToUpdate[field] = value;

        let metas = this.getMetas();
        metas[this.state.activeTab] = meta;

        this.setState({metas})
    };

    renderExtra = ({regex, fieldType, index, tags}) => {
        if (!fieldType) return;

        switch (fieldType.toLowerCase()) {
            case "string":
                return <FormGroup className="extendFlex" style={{flex: 1}} label="Valore">
                    <Input
                        name="fieldName"
                        type="text"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={regex}
                        onChange={(e) => this.updateField(index, 'regex', e.target.value)}
                        placeholder="Inserire regex"
                    />
                </FormGroup>
            case "select":
                return <TagsInput
                    value={tags || []}
                    onChange={tags => this.updateField(index, 'tags', tags)}
                    tagProps={{className: 'react-tagsinput-tag info'}}
                />
        }
        return fieldType;
    }

    render() {
        return (
            <div>
                <Nav tabs>
                    {this.state?.metas?.map((metaElement, index) =>
                        <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === index})}
                                onClick={() => {
                                    this.toggle(index);
                                }}
                            >
                                {metaElement.description}<Button
                                color="danger"
                                size="sm"
                                onClick={() => this.deleteTab(index)}
                                className={classnames("btn-icon btn-link like")}
                            >
                                <i className="tim-icons icon-simple-remove"/>
                            </Button>
                            </NavLink>
                        </NavItem>
                    )}
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === this.state.metas ? this.state.metas.length + 1 : 1})}
                            onClick={() => {
                                this.addNewTab();
                            }}
                        >
                            +
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {this.state?.metas?.map((metaElement, index) =>
                        <TabPane tabId={index}>
                            <FormGroup>
                                <Label for="input_metasName">Metadati</Label>
                                <Input
                                    name="input_metasName"
                                    id="input_metasName"
                                    placeholder="SCATOLA"
                                    value={metaElement.description}
                                    onChange={(e) => {
                                        let metas = this.state.metas
                                        metas[this.state.activeTab].description = e.target.value
                                        this.setState({metas})
                                    }
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="input_eventToApply">Evento su cui applicare</Label>
                                <Input
                                    name="input_eventToApply"
                                    id="input_eventToApply"
                                    placeholder="Evento"
                                    value={metaElement.eventToApply}
                                    onChange={(e) => {
                                        let metas = this.state.metas
                                        metas[this.state.activeTab].eventToApply = e.target.value
                                        this.setState({metas})
                                    }
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="input_regEx">Espressione regolare per validazione</Label>
                                <Input
                                    name="input_regEx"
                                    id="input_regEx"
                                    placeholder="/BCP\d\d\d\d\d\d\d\d\d/g"
                                    value={metaElement.regEx}
                                    onChange={(e) => {
                                        let metas = this.state.metas
                                        metas[this.state.activeTab].regEx = e.target.value
                                        this.setState({metas})
                                    }
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="input_barcodeMetasSeparator">Separatore barcode metas</Label>
                                <Input
                                    name="input_barcodeMetasSeparator"
                                    id="input_barcodeMetasSeparator"
                                    placeholder="#"
                                    value={metaElement.barcodeMetasSeparator}
                                    onChange={(e) => {
                                        let metas = this.state.metas
                                        metas[this.state.activeTab].barcodeMetasSeparator = e.target.value
                                        this.setState({metas})
                                    }
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="input_barcodeMetasOrderWithSeparator">Ordinamento campi barcode con
                                    separatore</Label>
                                <Input
                                    name="input_barcodeMetasOrderWithSeparator"
                                    id="input_barcodeMetasOrderWithSeparator"
                                    placeholder="scatola#descrizione#da#a"
                                    value={metaElement.barcodeMetasOrderWithSeparator}
                                    onChange={(e) => {
                                        let metas = this.state.metas
                                        metas[this.state.activeTab].barcodeMetasOrderWithSeparator = e.target.value
                                        this.setState({metas})
                                    }
                                    }

                                />
                            </FormGroup>
                            {
                                this.props.commessaId &&
                                <FormGroup>
                                    <Label for="input_barcodeMetasOrderWithSeparator">Report associato</Label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={this.props.reports.filter(report => report.id === this.state.metas[this.state.activeTab]?.report)}
                                        getOptionLabel={(item) => item.descrizione}
                                        getOptionValue={(item) => item.id}
                                        closeMenuOnSelect={true}
                                        onChange={value => {
                                            let metas = this.state.metas
                                            metas[this.state.activeTab].report = value.id;
                                            metas[this.state.activeTab].tipologiaReport = value.tipologiaReport;
                                            this.setState({metas})
                                        }}
                                        options={this.props.reports.filter(report => report.commessaId === this.props.commessaId)}
                                        placeholder="Seleziona report associato"
                                    />
                                </FormGroup>
                            }
                            <div style={{marginBottom: 10}}></div>

                            {metaElement.list.map((el, index) => <div
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FormGroup className="extendFlex" style={{flex: 1}} q label="Chiave">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={options.find(e => e.value === el.fieldType)}
                                        getOptionLabel={(item) => item.description}
                                        getOptionValue={(item) => item.id}
                                        closeMenuOnSelect={true}
                                        onChange={value => {
                                            this.updateFieldType(el.index, value.value);
                                        }}
                                        options={options}
                                        placeholder="Seleziona tipologia"
                                    />
                                </FormGroup>
                                <div style={{width: '5px'}}/>
                                <FormGroup className="extendFlex" style={{flex: 1}} label="Valore">
                                    <Input
                                        name="fieldName"
                                        type="text"
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={el.fieldName}
                                        onChange={(e) => this.updateFieldName(el.index, `${e.target.value}`)}
                                        placeholder="Inserire descrizione"
                                    />
                                </FormGroup>
                                {this.renderExtra({...el, index})}


                                <Button
                                    className={"h-100 ml-3"}
                                    color="danger"
                                    size="sm"
                                    onClick={() => this.removeItemFromList(el.index)}
                                >
                                    <i className="tim-icons icon-simple-remove"/>
                                </Button>
                            </div>)}
                            <button className="btn btn-primary w-100"
                                    onClick={this.addNewMeta}><i
                                className="tim-icons icon-simple-add"/>Aggiungi campo
                            </button>
                        </TabPane>
                    )}
                </TabContent>


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        reports: get(state, "report.data", []),
        loading: get(state, "report.loading", false)
    };

    if (data.reports === null) data.reports = [];

    return data;
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(CommesseMetasComponent);
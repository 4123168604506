// @ts-nocheck
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Col,
  Row,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import store from "../../../store/store";
import { created } from "../redux/etichetteBobina.actions";
import GraphqlClient from "../../../client/graphql.client";
import Select from "react-select";

import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";

const ExportReportModal: FunctionComponent = (props: any) => {
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [elencoPallets, setElencoPallets] = useState([]);
  const [palletsSelezionatiObj, setPalletsSelezionatiObj] = useState([]);

  useEffect(() => {
    const ottieniDati = async () => {
      try {
        const res = await GraphqlClient.elencoPallets({
          commesaIds: [props.data.commessaSelezionata.id],
        });
        const pallets = res.elencoPallets.map((pallet) => ({
          label: pallet,
          value: pallet,
        }));
        setElencoPallets(pallets);
      } catch (err) {
        alert(err);
      } finally {
      }
    };

    ottieniDati();
  }, []);

  const downloadCsvFile = (content) => {
    const linkSource = `data:text/csv;charset=utf-8,${content}`;
    const downloadLink = document.createElement("a");
    const date = new Date().toISOString().split("T")[0];
    const fileName =
      commessa.descrizione.replaceAll(" ", "_") + "-" + date + ".csv";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdfFile = (pdf, name) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = name + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const scaricaReport = async () => {
    setLoading(true);
    let pl =
      "[" + palletsSelezionatiObj.map((pal) => "'" + pal.value + "'") + "]";

    if (palletsSelezionatiObj.length === 0) {
      pl = "[" + elencoPallets.map((pal) => "'" + pal.value + "'") + "]";
    }
    console.log("PPPPP", pl);

    if (props.data.reportType.toLowerCase() === "verbale csv") {
      try {
        const res = await GraphqlClient.downloadReportVerbaleCsv({
          commessaId: props.data.commessaSelezionata.id,
          filters: JSON.stringify({
            filtroPallets: pl,
            filtroCommessaId: props.data.commessaSelezionata.id,
          }),
        });

        if (res.error) {
          alert("Impossibile scaricare il report");
          return;
        }

        const linkSource = `data:text/csv;charset=utf-8,${res.downloadReportVerbaleCsv}`;
        const downloadLink = document.createElement("a");
        const fileName = "verbale.csv";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        alert("Creazione report non riuscita:", e);
      } finally {
        setLoading(false);
      }
    } else if (props.data.reportType.toLowerCase() === "csv marica/postel") {
      console.log("2");
      try {
        const res = await GraphqlClient.downloadReportMaricaCsv({
          commessaId: props.data.commessaSelezionata.id,
          filters: JSON.stringify({
            filtroPallets: pl,
            filtroCommessaId: props.data.commessaSelezionata.id,
          }),
        });

        if (res.error) {
          alert("Impossibile scaricare il report");
          return;
        }

        const linkSource = `data:text/csv;charset=utf-8,${res.downloadReportMaricaCsv}`;
        const downloadLink = document.createElement("a");
        const fileName = "csvMarica.csv";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        alert("Creazione report non riuscita:", e);
      } finally {
        setLoading(false);
      }
    } else if (props.data.reportType.toLowerCase() === "csv marica/dorsoduro") {
      console.log("2");
      try {
        const res = await GraphqlClient.downloadReportMaricaDorsoduroCsv({
          commessaId: props.data.commessaSelezionata.id,
          filters: JSON.stringify({
            filtroPallets: pl,
            filtroCommessaId: props.data.commessaSelezionata.id,
          }),
        });

        if (res.error) {
          alert("Impossibile scaricare il report");
          return;
        }

        const linkSource = `data:text/csv;charset=utf-8,${res.downloadReportMaricaDorsoduroCsv}`;
        const downloadLink = document.createElement("a");
        const fileName = "csvMaricaDorsoduro.csv";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        alert("Creazione report non riuscita:", e);
      } finally {
        setLoading(false);
      }
    } else if (
      props.data.reportType.toLowerCase() === "csv marica/postel faldone"
    ) {
      try {
        const res = await GraphqlClient.downloadReportMaricaFaldoneCsv({
          commessaId: props.data.commessaSelezionata.id,
          filters: JSON.stringify({
            filtroPallets: pl,
            filtroCommessaId: props.data.commessaSelezionata.id,
          }),
        });

        if (res.error) {
          alert("Impossibile scaricare il report");
          return;
        }

        const linkSource = `data:text/csv;charset=utf-8,${res.downloadReportMaricaFaldoneCsv}`;
        const downloadLink = document.createElement("a");
        const fileName = "csvMaricaFaldone.csv";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        alert("Creazione report non riuscita:", e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await GraphqlClient.downloadReportByTipologia({
          tipologiaReport: props.data.reportType,
          commessaId: props.data.commessaSelezionata.id,
          filters: JSON.stringify({
            filtroPallets: pl,
            filtroCommessaId: props.data.commessaSelezionata.id,
          }),
        });

        if (res.error) {
          alert("Impossibile scaricare il report");
          return;
        }

        downloadPdfFile(
          res.downloadReportByTipologia,
          props.data.reportType +
            "_" +
            props.data.commessaSelezionata.descrizione
        );
      } catch (e) {
        alert("Creazione report non riuscita:", e);
      } finally {
        setLoading(false);
      }
    }

    props.toggle();
  };

  return (
    <Modal modalClassName="modal-black" isOpen={true} toggle={props.toggle}>
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">
          Scarica report {props.data.reportType} (
          {props.data.commessaSelezionata.descrizione})
        </h6>
      </div>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            salvaLotto();
          }}
        >
          <FormGroup>
            <Label for="contratto">Elenco pallets</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              isMulti={true}
              value={palletsSelezionatiObj}
              onChange={(value) => setPalletsSelezionatiObj(value)}
              options={elencoPallets}
              placeholder="Non selezionare nessun pallets per scaricarli tutti"
            />
          </FormGroup>

          {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
          <button style={{ display: "none" }} type="submit" />
        </form>
        <CSSTransition
          in={submitError}
          appear={true}
          unmountOnExit
          classNames="error"
          timeout={300}
        >
          <blockquote className="blockquote blockquote-danger">
            <p className="mb-0 text-danger">Errore durante il download.</p>
          </blockquote>
        </CSSTransition>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={scaricaReport}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Scarica"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExportReportModal;

import React, {Component} from 'react';
import {
    Button,
    FormGroup,
    Input,
    Label,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardText,
    Row,
    Col
} from "reactstrap";
import Select from "react-select";
import * as _ from 'lodash';
import classnames from 'classnames';
import {TextInputHook} from "../../../hooks/text_input.hook";
import {meters2ScreenPixels} from "google-map-react";

const options = [
    {
        value: "",
        description: "Tipologia",
        isDisabled: true
    },
    {
        value: "currency",
        description: "Valuta",
    },
    {
        value: "unix",
        description: "Data",
    },
    {
        value: "month",
        description: "Mese",
    },
    {
        value: "string",
        description: "Stringa",
    },
    {
        value: "integer",
        description: "Numero intero",
    },
    {
        value: "float",
        description: "Numero con decimali",
    }

];

class DdtMetasComponent extends Component {


    constructor(props) {

        super(props);

        let count = 0;
        let max = _.maxBy(_.get(this.props, "metas", []), 'index');

        if (max) count = max.index;

        this.state = {
            metas: [],
            activeTab: 1,
            count
        };
    }


    toggle = (tab) => {
        if (this.state.activeTab !== tab) this.setState({activeTab: tab})
    }

    getMetas = () => this.state.metas;

    removeItemFromList = (index) => {
        this.setState({metas: this.getMetas().filter((el) => el.index !== index)})
    };

    addNewMeta = (e) => {
        e.preventDefault();
        const metas = _.orderBy([...this.state.metas, {
            index: this.state.metas.length + 1,
            fieldName: null,
            fieldType: null
        }])

        this.setState({metas})
    };

    updateFieldValue = (index, fieldName, value) => {

        let metas = this.getMetas()
        const preUpdate = this.getMetas().filter((el) => el.index === index)[0];
        const postUpdate = {
            ...preUpdate,
            [fieldName]: value
        }
        metas = _.orderBy([...metas.filter((el) => el.index !== index), postUpdate], "index")
        this.setState({metas})
        console.log(postUpdate)

    };

    render() {
        return (
            <div>
                {this.state.metas.map((el) => <div
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <Input
                        name="fieldName"
                        type="text"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={el.quantita}
                        onChange={(e) => this.updateFieldValue(el.index, "quantita", `${e.target.value}`)}
                        placeholder="Q.tà"
                    />
                    <Input
                        name="fieldName"
                        type="text"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={el.descrizione}
                        onChange={(e) => this.updateFieldValue(el.index, "descrizione", `${e.target.value}`)}
                        placeholder="Descrizione"
                    />
                    <Input
                        name="fieldName"
                        type="text"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={el.importo}
                        onChange={(e) => this.updateFieldValue(el.index, "importo", `${e.target.value}`)}
                        placeholder="Importo"
                    />
                    <Button
                        className={"h-100 ml-3"}
                        color="danger"
                        size="sm"
                        onClick={() => this.removeItemFromList(el.index)}
                    >
                        <i className="tim-icons icon-simple-remove"/>
                    </Button>
                </div>)}
                <button className="btn btn-primary w-100"
                        onClick={this.addNewMeta}><i
                    className="tim-icons icon-simple-add"/>Aggiungi riga
                </button>


            </div>
        );
    }
}

export default DdtMetasComponent;

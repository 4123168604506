// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created} from "../redux/societa.actions";
import GraphqlClient from "../../../client/graphql.client";

import {TextInputHook} from "../../../hooks/text_input.hook";
import Select from "react-select";

const SocietaCreateComponent: FunctionComponent = (props: any) => {


    const input_ragioneSociale = TextInputHook("");
    const input_indirizzo = TextInputHook("");
    const [companyType, setCompanyType] = useState([]);

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salva = async () => {
        try {
            setLoading(true);

            const res = await GraphqlClient.creaSocieta(
                {
                    input: {
                        id: "",
                        ragioneSociale: input_ragioneSociale.getValue(),
                        indirizzo: input_indirizzo.getValue(),
                        companyType: companyType.toString(),
                        comune: "",
                        partitaIva: "",
                        recapito: "",
                    }
                }
            );

            store.dispatch(created(res.creaSocieta)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>

                    <FormGroup>
                        <Label for="input_ragioneSociale">Ragione sociale</Label>
                        <Input
                            {...input_ragioneSociale.input}
                            name="input_ragioneSociale"
                            id="input_ragioneSociale"
                            placeholder="Inserisci ragione sociale"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_indirizzo">Indirizzo</Label>
                        <Input
                            {...input_indirizzo.input}
                            name="input_indirizzo"
                            id="input_indirizzo"
                            placeholder="Inserisci indirizzo"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_companyType">Tipologia</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            multiple={false}
                            value={companyType}
                            getOptionLabel={(item) => item}
                            getOptionValue={(item) => item}
                            onChange={value => {
                                setCompanyType(value);
                            }
                            }
                            closeMenuOnSelect={false}
                            isMulti
                            options={["CLIENTE", "CLIENTE COMMITTENTE"]}
                            placeholder="Seleziona un contratto"
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default SocietaCreateComponent;

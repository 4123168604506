import React from 'react';
/*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR IMPORT*/
import Ddt from "./pages/ddt/ddt.component"
import Report from "./pages/report/report.component"
import PalletView from "./pages/palletView/palletView.component"
import Societa from "./pages/societa/societa.component"
import Commesse from "./pages/commesse/commesse.component"
import EtichetteBobina from "./pages/etichetteBobina/etichetteBobina.component"
import BarcodeSequenza from "./pages/barcodeSequenza/barcodeSequenza.component"
import Movimento from "./pages/movimento/movimento.component"
import Utente from "./pages/utente/utente.component"
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Login/login";
import {RUOLO_AMMINISTRATORE, RUOLO_OPERATORE} from "./pages/utente/components/costants";

const routes = [

    // {
    //     path: "/dashboard",
    //     name: "Dashboard",
    //     icon: "tim-icons icon-chart-bar-32",
    //     component: Dashboard,
    //     layout: "/admin",
    //     roles: [RUOLO_OPERATORE, RUOLO_AMMINISTRATORE]
    // },
    {
        path: "/login",
        icon: "tim-icons icon-image-02",
        name: "Login",
        component: Login,
        layout: "/auth",
        visible: false
    },
    /*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR ROUTES*/
    {
        path: "/palletView",
        icon: "tim-icons icon-image-02",
        name: "Pallets",
        component: PalletView,
        layout: "/admin"
    },

    {
        path: "/movimento",
        icon: "tim-icons icon-image-02",
        name: "Movimenti",
        component: Movimento,
        layout: "/admin",
        roles: [RUOLO_OPERATORE, RUOLO_AMMINISTRATORE]

    },

    {
        path: "/ddt",
        icon: "tim-icons icon-image-02",
        name: "Ddt",
        component: Ddt,
        layout: "/admin"
    },

    {
        path: "/etichetteBobina",
        icon: "tim-icons icon-image-02",
        name: "Etichette",
        component: EtichetteBobina,
        layout: "/admin",
        roles: [RUOLO_AMMINISTRATORE]
    },


    {
        collapse: true,
        name: "Tabelle",
        icon: "iconmoon-square",
        state: "Tabelle2PagesCollapse",
        roles: [RUOLO_AMMINISTRATORE],
        views: [
            {
                path: "/societa",
                icon: "tim-icons icon-image-02",
                name: "Societa'",
                component: Societa,
                layout: "/admin"
            },
            {
                path: "/commesse",
                icon: "tim-icons icon-image-02",
                name: "Commesse",
                component: Commesse,
                layout: "/admin"
            },
            {
                path: "/utente",
                icon: "tim-icons icon-image-02",
                name: "Utenti",
                component: Utente,
                layout: "/admin",
            },
            {
                path: "/report",
                icon: "tim-icons icon-image-02",
                name: "Report",
                component: Report,
                layout: "/admin"
            },

            {
                path: "/barcodeSequenza",
                icon: "tim-icons icon-image-02",
                name: "Sequenza",
                component: BarcodeSequenza,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE]
            },


        ]
    },


];

export default routes;

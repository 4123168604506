// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created} from "../redux/utente.actions";
import GraphqlClient from "../../../client/graphql.client";
import Select from "react-select";

import {TextInputHook} from "../../../hooks/text_input.hook";

const UtenteCreateComponent: FunctionComponent = (props: any) => {


    const utente_nome = TextInputHook("");
    const utente_cognome = TextInputHook("");
    const utente_password = TextInputHook("");
    const utente_confermaPassword = TextInputHook("");
    const utente_email = TextInputHook("");

    const [utente_ruolo, setUtente_ruolo] = useState({});
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salva = async () => {
        try {
            setLoading(true);

            const res = await GraphqlClient.creaUtente(
                {
                    utente: {
                        nome: utente_nome.getValue(),
                        cognome: utente_cognome.getValue(),
                        ruolo: utente_ruolo.value,
                        password: utente_password.getValue(),
                        confermaPassword: utente_confermaPassword.getValue(),
                        email: utente_email.getValue(),
                        tags: []
                    }
                }
            );

            store.dispatch(created(res.creaUtente)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaLotto();
                }}>


                    <FormGroup>
                        <Label for="utente_nome">Nome</Label>
                        <Input
                            {...utente_nome.input}
                            name="utente_nome"
                            id="utente_nome"
                            placeholder="Inserisci nome"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="utente_cognome">Cognome</Label>
                        <Input
                            {...utente_cognome.input}
                            name="utente_cognome"
                            id="utente_cognome"
                            placeholder="Inserisci cognome"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="ruolo">Ruolo</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={utente_ruolo}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setUtente_ruolo(value)
                            }
                            options={[{
                                value: "Amministratore",
                                description: "Amministratore",
                            },{
                                value: "Operatore",
                                description: "Operatore",
                            }, ]}
                            placeholder="Seleziona un ruolo"
                        />
                        <FormText color="muted">
                            Ruolo a cui si riferisce l'utente
                        </FormText>
                    </FormGroup>

                    <FormGroup>
                        <Label for="utente_password">Password</Label>
                        <Input
                            {...utente_password.input}
                            type="password"
                            name="utente_password"
                            id="utente_password"
                            placeholder="Inserisci password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="utente_confermaPassword">Conferma password</Label>
                        <Input
                            {...utente_confermaPassword.input}
                            type="password"
                            name="utente_confermaPassword"
                            id="utente_confermaPassword"
                            placeholder="Inserisci conferma password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="utente_email">E-Mail</Label>
                        <Input
                            {...utente_email.input}
                            name="utente_email"
                            id="utente_email"
                            placeholder="Inserisci email"
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default UtenteCreateComponent;

import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    CardSubtitle,
    ButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect, useSelector} from 'react-redux';
import {get} from 'lodash';
import './redux/movimento.reducer';
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/movimento.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import moment from 'moment';
import ReactTableFilterSelectComponent from "../../components/ReactTableFilter/reactTable.filter.select.component";
import DateRangePickerWrapper from "../../components/DateRangePicker/DateRangePickerWrapper";
import {CSVDownload} from "react-csv";
import {isAdmin} from "../../utilities/constants";
import ExportPackingPallet from "./modals/packingpallet";
import ExportVerbale from "./modals/verbale";
import ExportCsv from "./modals/tracciatoCsv";
import Input from "reactstrap/es/Input";
import Select from "react-select";


let csvData = [
    ["DATA", "UBICAZIONE", "SCATOLA", "UTENTE", "ANNO", "TIPOLOGIA DOCUMENTO", "TIPOLOGIA MOVIMENTO", "TIPOLOGIA UBICAZIONE"],
];

class movimentoComponent extends Component {

    initialState = {
        data: [],
        loading: false,
        showDeleteAlert: false,
        selectedRecordForDelete: {},
        filters: {},
        initialStartDate: new moment(),
        initialEndDate: new moment(),
        startDate: new moment(),
        endDate: new moment(),
        showReportDropDown: false,
        downloadCsv: false,
        showExportDropDown: false,
        showExportPackingPallet: false,
        showExportVerbale: false,
        showExportCsv: false,
        fullText: "",
        limit: 200,
        elencoCommesse: store.getState().commesse.data,
        commessaSelezionata: {},
    }
    state = {...this.initialState}


    utenteAmministratore = isAdmin()


    caricaDati = async () => {
        try {

            const startDateClone = this.state.startDate.clone();
            const endDateClone = this.state.endDate.clone();

            startDateClone.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            endDateClone.set({hour: 23, minute: 59, second: 59, millisecond: 0});

            const s = startDateClone.unix()
            const e = endDateClone.unix()

            const response = await GraphqlClient.elencoMovimentiRicercaInPeriodo({
                startDate: s,
                endDate: e,
                filter: this.state.fullText,
                limit: this.state.limit,
                commessaId: this.state.commessaSelezionata.id,
            });

            store.dispatch(readedAll(response.elencoMovimentiRicercaInPeriodo)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }


    toggleShowReportDropDown = () => (this.setState({showReportDropDown: !this.state.showReportDropDown}));
    toggleShowExportPackingPallet = () => (this.setState({showExportPackingPallet: !this.state.showExportPackingPallet}));
    toggleShowExportVerbale = () => (this.setState({showExportVerbale: !this.state.showExportVerbale}));
    toggleShowExportCsv = () => (this.setState({showExportCsv: !this.state.showExportCsv}));

    onDatesChanged(startDate, endDate) {
        this.setState({startDate, endDate}, this.caricaDati)
    }

    componentDidMount() {
        this.caricaDati();
    }


    elimina = async () => {
        try {
            this.toggleDeleteAlert(this.state.selectedRecordForDelete);
            store.dispatch(requestDelete(this.state.selectedRecordForDelete.id))
            await GraphqlClient.eliminaMovimento({id: this.state.selectedRecordForDelete.id});
            store.dispatch(deleted({id: this.state.selectedRecordForDelete.id})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare il record', e);
            store.dispatch(deleteError(this.state.selectedRecordForDelete.id));
        }

    }

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    toggleShowExportDropDown = () => {
        this.setState({showExportDropDown: !this.state.showExportDropDown});
    }


    toggleDownloadCsv = () => {
        const records = this.selectTable.getResolvedState().sortedData;

        csvData = [["DATA", "UBICAZIONE", "SCATOLA", "UTENTE", "ANNO", "TIPOLOGIA DOCUMENTO", "TIPOLOGIA MOVIMENTO", "TIPOLOGIA UBICAZIONE"]];

        records.forEach((v) => {
            csvData.push([moment.unix(v.dataMovimento).format("DD/MM/YYYY hh:mm"), v.barcodeUbicazione, v.barcodeAllocato, v.utente, v.anno, v.tipologia, v.tipologiaMovimento, v.tipologiaUbicazione])
        })

        const callBack = () => {
            this.setState({downloadCsv: false})
        }

        this.setState({downloadCsv: true}, callBack)

    }


    actions = (cellInfo) => {

        return <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            {<Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>}

        </div>

    }

    getColumns = () => {
        let columns = [
            {
                Header: "Data",
                accessor: "dataMovimento",
                Cell: row => {
                    if (!row.value) return "-";
                    return <div>{moment.unix(row.value).format("DD/MM/YYYY HH:mm")}</div>
                },
                width: 150,
            },
            {
                Header: "Utente",
                accessor: "utente",
                width: 300,
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.utente);
                },
                Filter: ({onChange}) => {
                    const filterName = 'utente';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, ['utente'])}
                        optionValue={'utente'}
                        optionLabel={'utente'}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }

            },
            {
                Header: "Tipologia",
                accessor: "tipologiaMovimento",
                width: 150,
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.tipologiaMovimento);
                },
                Filter: ({onChange}) => {
                    const filterName = 'tipologiaMovimento';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, ['tipologiaMovimento'])}
                        optionValue={'tipologiaMovimento'}
                        optionLabel={'tipologiaMovimento'}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            {
                Header: "Allocato",
                accessor: "barcodeAllocato",
                width: 150,
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.barcodeAllocato);
                },
                Filter: ({onChange}) => {
                    const filterName = 'barcodeAllocato';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, ['barcodeAllocato'])}
                        optionValue={'barcodeAllocato'}
                        optionLabel={'barcodeAllocato'}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            {
                Header: "Ubicazione",
                accessor: "barcodeUbicazione",
                width: 150,
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.barcodeUbicazione);
                },
                Filter: ({onChange}) => {
                    const filterName = 'barcodeUbicazione';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, ['barcodeUbicazione'])}
                        optionValue={'barcodeUbicazione'}
                        optionLabel={'barcodeUbicazione'}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }

            },
            {
                Header: "Tipo ubicazione",
                accessor: "tipologiaUbicazione",
                width: 150,
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.tipologiaUbicazione);
                },
                Filter: ({onChange}) => {
                    const filterName = 'tipologiaUbicazione';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, ['tipologiaUbicazione'])}
                        optionValue={'tipologiaUbicazione'}
                        optionLabel={'tipologiaUbicazione'}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            // {
            //     Header: "Commessa",
            //     id: "commessa",
            //     Cell: row => {
            //         if (row && row.original && row.original.commessa && row.original.commessa.descrizione) {
            //             return row.original.commessa.descrizione
            //         }
            //         return "-"
            //     },
            //
            //     width: 150,
            //     filterMethod: (filter, row) => {
            //         if (!filter || !filter?.value) return true
            //
            //         if (filter && filter.value && filter.value.length === 0) return true;
            //         return filter.value.some(f => f.value === row._original.commessa.descrizione);
            //     },
            //     Filter: ({onChange}) => {
            //         const filterName = 'commessa.descrizione';
            //
            //         return <ReactTableFilterSelectComponent
            //             options={_.sortBy(this.props.data, [filterName])}
            //             optionValue={filterName}
            //             optionLabel={filterName}
            //             filterName
            //             value={this.state.filters[filterName]}
            //             onChange={(value) => this.setState({
            //                 filters: {
            //                     ...this.state.filters,
            //                     [filterName]: value
            //                 }
            //             }, () => onChange(value))}
            //         />
            //     }
            //
            // },


        ]

        if (this.state.commessaSelezionata?.id) {
            let metaColumns = []
            this.state.commessaSelezionata.metas.forEach((meta) => {
                meta.list.forEach((m) => {
                    metaColumns = [...metaColumns,
                        {
                            Header: m.fieldName,
                            accessor: "metas." + m.fieldName,
                            width: 150,
                            filterMethod: (filter, row) => {
                                if (!filter || !filter?.value) return true
                                if (filter && filter.value && filter.value.length === 0) return true;
                                return filter.value.some(f => f.value === row["metas." + m.fieldName]);
                            },
                            Filter: ({onChange}) => {
                                const filterName = 'metas.' + m.fieldName;

                                return <ReactTableFilterSelectComponent
                                    options={_.sortBy(this.props.data, [filterName])}
                                    optionValue={filterName}
                                    optionLabel={filterName}
                                    filterName
                                    value={this.state.filters[filterName]}
                                    onChange={(value) => this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            [filterName]: value
                                        }
                                    }, () => onChange(value))}
                                />
                            }

                        },
                    ]
                })
            })
            columns = [...columns, ...metaColumns]
        }
        return columns
    }

    downloadReportTestapacco = async () => {
        try {
            this.setState({downloadReportInProgress: true});
            const ids = this.dataTableIds();

            const res = await GraphqlClient.scaricaReportTestaPacco({ids: ids});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            downloadPDF(res.scaricaReportTestaPacco)

        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            this.setState({downloadReportInProgress: false});

        }


    };

    downloadReportErrori = async () => {
        try {
            this.setState({downloadReportInProgress: true});
            const ids = this.dataTableIds();

            const res = await GraphqlClient.stampaReportErrori({ids: ids});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            downloadPDF(res.stampaReportErrori)

        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            this.setState({downloadReportInProgress: false});

        }


    };

    downloadPackingPalletByCommessa = async () => {
        try {
            this.setState({downloadReportInProgress: true});
            const ids = this.dataTableCommessa();

            const res = await GraphqlClient.stampaContenutoPalletByCommessa({commessaId: ids[0]});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            downloadPDF(res.stampaContenutoPalletByCommessa)

        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            this.setState({downloadReportInProgress: false});

        }


    };

    dataTableIds = () => {
        const wrappedInstance = this.selectTable;

        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance ? wrappedInstance.getResolvedState().sortedData : this.props.data;
        const data = currentRecords.map(item => item._original.id);
        return data;
    };

    dataTableCommessa = () => {
        const wrappedInstance = this.selectTable;

        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance ? wrappedInstance.getResolvedState().sortedData : this.props.data;
        const data = currentRecords.map(item => item._original.commessa.id);
        return _.uniq(data);
    };

    render() {
        return (
            <>

                {
                    this.state.showExportPackingPallet ?
                        <ExportPackingPallet toggle={this.toggleShowExportPackingPallet}/> : null
                }
                {
                    this.state.showExportVerbale ? <ExportVerbale toggle={this.toggleShowExportVerbale}/> : null
                }
                {
                    this.state.showExportCsv ? <ExportCsv toggle={this.toggleShowExportCsv}/> : null
                }
                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warni
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {this.state.selectedRecordForDelete?.barcodeAllocato}
                        </ReactBSAlert> : null
                }


                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col xs={12} sm={12} lg={5}>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={this.state.commessaSelezionata}
                                                getOptionLabel={(item) => item.descrizione}
                                                onChange={value =>
                                                    this.setState({commessaSelezionata: value}, () => this.caricaDati())
                                                }
                                                options={[...this.state.elencoCommesse]}
                                                placeholder="Seleziona commessa"
                                            />
                                        </Col>

                                        <Col xs={10} sm={12} lg={3}>
                                            <DateRangePickerWrapper
                                                disabled={false}
                                                initialStartDate={this.state.initialStartDate} // momentPropTypes.momentObj or null,
                                                startDateId="startDate" // PropTypes.string.isRequired,
                                                initialEndDate={this.state.initialEndDate} // momentPropTypes.momentObj or null,
                                                endDateId="endDate" // PropTypes.string.isRequired,
                                                small
                                                enableOutsideDays={true}
                                                startDatePlaceholderText={"Data inizio"}
                                                endDatePlaceholderText={"Data fine"}
                                                showDefaultInputIcon
                                                isOutsideRange={() => false}
                                                onChange={(startDate, endDate) => this.onDatesChanged(startDate, endDate)}
                                            />
                                        </Col>

                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati(this.state.startDate, this.state.endDate);
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={12} md={2}>
                                            <CardTitle tag="h4">Movimenti</CardTitle>
                                            <Fade when={this.props.loading}>
                                                <CardSubtitle>Caricamento in corso <i
                                                    className="fa fa-spin fa-spinner"/></CardSubtitle>
                                            </Fade>
                                        </Col>
                                        <Col xs={12} sm={12} md={8}>
                                            <Input
                                                onChange={(e) => {
                                                    console.log("EEEE", e.target.value, e.target.value.length)
                                                    if (e.target.value.length >= 3) {
                                                        this.setState({fullText: e.target.value}, () => {
                                                            this.caricaDati()
                                                        })
                                                    } else {
                                                        this.setState({fullText: e.target.value})
                                                    }
                                                }}
                                                value={this.state.fullText} placeHolder="Ricerca...."/>
                                        </Col>
                                        <Col xs={12} sm={12} md={2}>
                                            <Input onChange={(e) => {
                                                this.setState({limit: e.target.value});
                                            }}
                                                   value={this.state.limit} placeHolder="Limite...."/>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        ref={(r) => {
                                            this.selectTable = r;
                                        }}
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        columns={[...this.getColumns(), {
                                            Header: "Azioni",
                                            Cell: this.actions,
                                            sortable: false,
                                            filterable: false,
                                            show: isAdmin(),
                                        }]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "movimento.data", []),
        loading: get(state, "movimento.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(movimentoComponent)

function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "report.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

//@flow
import React, {Component} from "react";
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function renderOnDoc(fn) {
    const elemDiv = document.createElement("div");
    elemDiv.style.cssText =
        "position:absolute;width:100%;height:100%;top:0px;opacity:1;z-index:9999999;";
    document.body.appendChild(elemDiv);
    const handleClose = () => {
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(elemDiv);
            document.body.removeChild(elemDiv);
        });
    };
    return ReactDOM.render(fn(handleClose), elemDiv);
}

export default function ShowConfirmationDialog(opts) {
    return new Promise(resolve => {
        renderOnDoc(handleClose => {
            return <AlertWrapper {...{...opts, handleClose, resolve}} />;
        });
    });
}

class AlertWrapper extends Component {
    state = {isOpen: true};

    render() {
        const {
            handleClose,
            resolve,
            ...rest
        } = this.props;

        const doClose = (confirm, response) => {
            handleClose();
            this.setState({isOpen: false});
            resolve({confirm, response});
        };

        return (
            <SweetAlert
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                show={this.state.isOpen}
                confirmBtnText={this.props.confirmBtnText || "Si"}
                cancelBtnText={this.props.cancelBtnText || "No"}
                showCancel
                style={{display: "block"}}
                title={this.props.title || "Confermare"}
                onCancel={() => doClose(false)}
                onConfirm={(response) => {
                    doClose(true, response);
                    if (this.props.onConfirm) this.props.onConfirm(response);
                }}
                {...rest}
                input={this.props.input}
            >
                {this.props.text || this.props.html}
            </SweetAlert>
        );
    }
}

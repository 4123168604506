import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle,
    ButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/etichetteBobina.reducer';
import EtichetteBobinaCreateComponent from "./components/etichetteBobina.create.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/etichetteBobina.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import ReactTableFilterSelectComponent from "../../components/ReactTableFilter/reactTable.filter.select.component";
import moment from "moment";
import {isAdmin} from "../../utilities/constants";
import ShowConfirmationDialog from "../../components/ShowConfirmationDialog";
import EtichetteBobinaUpdateComponent from "./components/etichetteBobina.update.component";

class etichetteBobinaComponent extends Component {

    state = {
        data: [],
        loading: false,
        selectedRecord: {},
        filters: {},
        showDeleteAlert: false,
        showReportDropDown: false,
        showUpdateModal: false,
        showCloneModal: false,
    }

    caricaDati = async () => {
        try {
            const response = await GraphqlClient.elencoEtichetteBobina(

            );
            store.dispatch(readedAll(response.elencoEtichetteBobina)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }


    componentDidMount() {
        this.caricaDati();
    }

    elimina = async () => {
        try {
            this.toggleDeleteAlert(this.state.selectedRecord);
            store.dispatch(requestDelete(this.state.selectedRecord.id))
            await GraphqlClient.eliminaEtichetteBobina({id: this.state.selectedRecord.id});
            store.dispatch(deleted({id: this.state.selectedRecord.id})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare il record', e);
            store.dispatch(deleteError(this.state.selectedRecord.id));
        }

    }

    toggleShowReportDropDown = () => (this.setState({showReportDropDown: !this.state.showReportDropDown}));

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecord: record
    });

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecord: record
        })
    }

    toggleShowCloneModal = (record) => {
        this.setState({
            showCloneModal: !this.state.showCloneModal,
            selectedRecord: record
        })
    }


    stampa = async (record) => {

        const doAction = await ShowConfirmationDialog({
            text:
                "Procedere alla stampa delle etichette, accertati di aver avviato il client sul tuo computer nella rete dove é presente la stampante ?",
            canEscapeKeyCancel: true //this is false by default
        });

        if (doAction.confirm === true) {
            try {
                await GraphqlClient.stampaEtichetteBobina({id: record.id});
            } catch (e) {
                console.log('Impossibile stampare', e);
            }
        }

    }

    download = async (record) => {

        try {
            const res = await GraphqlClient.downloadEtichetteBobinaPDF({id: record.id});
            downloadPDF(res.downloadEtichetteBobinaPDF.data)

        } catch (e) {
            console.log('Impossibile scaricare il file', e);
        }

    }

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowCloneModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-caps-small"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
            <Button
                color="success"
                size="sm"
                onClick={() => this.stampa(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-paper"/>
            </Button>{" "}
            <Button
                color="dialog"
                size="sm"
                onClick={() => this.download(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-cloud-download-93"/>
            </Button>{" "}
        </div>

    );

    render() {
        return (
            <>

                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecord.name", "")}
                        </ReactBSAlert> : null
                }

                {this.state.showCreateModal ? <EtichetteBobinaCreateComponent show={this.state.showCreateModal}
                                                                              toggle={this.toggleShowCreateModal}/> : null}

                {this.state.showCloneModal ? <EtichetteBobinaCreateComponent show={this.state.showCloneModal}
                                                                              toggle={this.toggleShowCloneModal}
                                                                              data={this.state.selectedRecord}/> : null}


                {this.state.showUpdateModal ? <EtichetteBobinaUpdateComponent show={this.state.showUpdateModal}
                                                                              toggle={this.toggleShowUpdateModal}
                                                                              data={this.state.selectedRecord}/> : null}


                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>

                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo lotto<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <ButtonDropdown isOpen={this.state.showReportDropDown}
                                                            toggle={this.toggleShowReportDropDown}>
                                                <DropdownToggle className="btn btn-primary" caret>
                                                    Label
                                                    Manager {this.state.downloadReportInProgress ? "(Download in corso...)" : null}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => downloadFileFromUrl("/labelServer.exe")}>Windows</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => downloadFileFromUrl("/labelServer.command")}>MacOSX</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        columns={[

                                            {
                                                Header: "dataCreazione",
                                                accessor: "dataCreazione",
                                                Cell: row => {
                                                    if (!row.value) return "-";
                                                    return <div>{moment.unix(row.value).format("DD/MM/YYYY hh:mm")}</div>
                                                },
                                                width: 150,

                                            },
                                            {
                                                Header: "descrizione",
                                                accessor: "descrizione",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.descrizione);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'descrizione';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "commessa",
                                                Cell: row => {
                                                    if (row && row.original && row.original.commessa && row.original.commessa.descrizione) {
                                                        return row.original.commessa.descrizione
                                                    }
                                                    return "-"
                                                },

                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.commessa.descrizione);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'commessa.descrizione';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "utente",
                                                accessor: "utente",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.utente);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'utente';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "dal",
                                                accessor: "dal",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.dal);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'dal';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "al",
                                                accessor: "al",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.al);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'al';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "tipologia",
                                                accessor: "tipologia",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.tipologia);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'tipologia';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "stampata",
                                                id: "stampata",
                                                Cell: row => {
                                                    if (row && row.original && row.original.stampata) {
                                                        return "STAMPATA"
                                                    }
                                                    return "DA STAMPARE"
                                                },

                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.stampata);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'stampata';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "Azioni",
                                                width:200,
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            }]
                                        }
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "etichetteBobina.data", []),
        loading: get(state, "etichetteBobina.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(etichetteBobinaComponent)


function downloadFileFromUrl(url) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.click();
}

const downloadPDF = (pdf) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "report.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

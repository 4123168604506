import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/report.reducer';
import ReportCreateComponent from "./components/report.create.component";
import ReportUpdateComponent from "./components/report.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/report.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import moment from "moment";
import ReactTableFilterSelectComponent from "../../components/ReactTableFilter/reactTable.filter.select.component";

class reportComponent extends Component {

    state = {
        data: [],
        loading: false,
        showCreateModal: false,
        showUpdateModal: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
        filters: {},
        elencoCommesse: store.getState().commesse.data,
        files: [],
    }

    caricaDati = async () => {
        try {
            const response = await GraphqlClient.elencoReports(

            );
            store.dispatch(readedAll(response.elencoReports)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }


    componentDidMount() {
        this.caricaDati();
    }


    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        }, () => console.log(this.state))

    }

    elimina = async () => {
        try {
            this.toggleDeleteAlert(this.state.selectedRecordForDelete);
            store.dispatch(requestDelete(this.state.selectedRecordForDelete.id))
            await GraphqlClient.eliminaReport({id: this.state.selectedRecordForDelete.id});
            store.dispatch(deleted({id: this.state.selectedRecordForDelete.id})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare il record', e);
            store.dispatch(deleteError(this.state.selectedRecordForDelete.id));
        }
    }

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    downloadTemplate = (record) => {
        const linkSource = `data:application/odt;base64,${record.contenuto}`;
        const downloadLink = document.createElement("a");
        const fileName = record.descrizione + "-" + record.tipologiaReport + ".odt";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    downloadPdf = async (record) => {
        const res = await GraphqlClient.downloadReport({
            reportid: record.id,
            commessaId: record.commessaId,
            filters: JSON.stringify({
                filtroPallets: "['%']",
                filtroNumeroDDT: "%",
            }),

        })

        const linkSource = `data:application/pdf;base64,${res.downloadReport}`;
        const downloadLink = document.createElement("a");
        const fileName = record.descrizione + "-" + record.tipologiaReport + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }

            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>
            <Button
                color="success"
                size="sm"
                onClick={() => this.downloadTemplate(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-cloud-download-93"/>
            </Button>
            <Button
                color="success"
                size="sm"
                onClick={() => this.downloadPdf(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-paper"/>
            </Button>
        </div>
    );

    render() {
        return (
            <>

                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.name", "")}
                        </ReactBSAlert> : null
                }
                <ReportCreateComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {this.state.showUpdateModal ? <ReportUpdateComponent
                    show={this.state.showUpdateModal}
                    data={this.state.selectedRecordForUpdate}
                    toggle={this.toggleShowUpdateModal}/> : null
                }


                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>

                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo lotto<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "dataCreazione",
                                                Cell: row => {

                                                    if (!row.original.dataCreazione) return "-";
                                                    return <div>{moment.unix(row.original.dataCreazione).format("DD/MM/YYYY hh:mm")}</div>
                                                },
                                                width: 150,
                                            },
                                            {
                                                Header: "utente",
                                                accessor: "utente",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.utente);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'utente';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "commessa",
                                                Cell: row => {
                                                    if (!row.original.commessaId) return "-";
                                                    if (row.original.commessaId == "default") return "default";
                                                    return <div>{
                                                        _.find(this.state.elencoCommesse, {'id': row.original.commessaId})?.descrizione
                                                    }</div>
                                                },


                                            },
                                            {
                                                Header: "descrizione",
                                                accessor: "descrizione",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.descrizione);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'descrizione';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "tipologiaReport",
                                                accessor: "tipologiaReport",
                                                filterMethod: (filter, row) => {
                                                    if (!filter || !filter?.value) return true
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.tipologiaReport);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'tipologiaReport';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            }]
                                        }
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "report.data", []),
        loading: get(state, "report.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(reportComponent)

import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/ddt.reducer';
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/ddt.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import CommesseCreateComponent from "../commesse/components/commesse.create.component";
import CommesseUpdateComponent from "../commesse/components/commesse.update.component";
import DdtCreateComponent from "./components/ddt.create.component";
import DdtUpdateComponent from "./components/ddt.update.component";
import EtichetteBobinaCreateComponent from "../etichetteBobina/components/etichetteBobina.create.component";


class ddtComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        loading: false,
        elencoCommesse: store.getState().commesse.data,
        selectedRecord: {},

    }

    caricaDati = async () => {
        try {
            const response = await GraphqlClient.elencoDdt(
                {startDate: 99025, endDate: 61607}
            );
            store.dispatch(readedAll(response.elencoDdt)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }

    elimina = async () => {
        try {
            const response = await GraphqlClient.eliminaDdt(
                {id: this.state.selectedRecord.id}
            );
            store.dispatch(deleted(response.eliminaDdt)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare', e);
        }
        this.toggleDeleteAlert()
    }

    stampa = async (record) => {
        try {

            const variables = {
                commessaId: record.commessa.id,
                tipologiaReport: "DDT",
                filters: JSON.stringify({"filtroNumeroDDT": record.numero})
            }

            const response = await GraphqlClient.downloadReportByTipologia(variables)
            this.downloadPDF(response.downloadReportByTipologia)

        } catch (e) {
            console.log('Impossibile eliminare', e);
        }
    }

    downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "report.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


    componentDidMount() {
        this.caricaDati();
    }


    actions = (cellInfo) => (
        <div className="actions-right">
            <Button
                color="success"
                size="sm"
                onClick={() => this.stampa(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-paper"/>
            </Button>
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowCloneModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-caps-small"/>
            </Button>
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>
        </div>
    );


    toggleShowCreateModal = () => this.setState({showCreateModal: !this.state.showCreateModal})
    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecord: record
    });
    toggleShowUpdateModal = (record) => {
        this.setState({showUpdateModal: !this.state.showUpdateModal, selectedRecord: record})
    }
    toggleShowCloneModal = (record) => {
        this.setState({showCloneModal: !this.state.showCloneModal, selectedRecord: record})
    }

    render() {
        return (

            <>

                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo il DDT numero: {_.get(this.state, "selectedRecord.numero", "")}
                        </ReactBSAlert> : null
                }

                {this.state.showCreateModal ? <DdtCreateComponent toggle={this.toggleShowCreateModal}/> : null}

                {this.state.showCloneModal ? <DdtCreateComponent show={this.state.showCloneModal}
                                                                 toggle={this.toggleShowCloneModal}
                                                                 data={this.state.selectedRecord}/> : null
                }
                {this.state.showUpdateModal ? <DdtUpdateComponent data={this.state.selectedRecord}
                                                                  toggle={this.toggleShowUpdateModal}/> : null
                }


                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>

                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuova DDT<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={
                                            [
                                                {
                                                    Header: "numero",
                                                    accessor: "numero"
                                                },
                                                {
                                                    Header: "data",
                                                    accessor: "data"
                                                },
                                                {
                                                    Header: "commessa",
                                                    accessor: "commessa.descrizione"
                                                },
                                                {
                                                    Header: "numeroColli",
                                                    accessor: "numeroColli"
                                                },
                                                {
                                                    Header: "pesoKg",
                                                    accessor: "pesoKg"
                                                },
                                                {
                                                    Header: "inizioTrasportoAMezzo",
                                                    accessor: "inizioTrasportoAMezzo"
                                                },
                                                {
                                                    Header: "numeroProggressivo",
                                                    accessor: "numeroProggressivo"
                                                },
                                                {
                                                    Header: "Azioni",
                                                    width: 180,
                                                    Cell: this.actions,
                                                    sortable: false,
                                                    filterable: false,
                                                }]
                                        }
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>


        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "ddt.data", []),
        loading: get(state, "ddt.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(ddtComponent)

import {GraphQLClient} from 'graphql-request';
import {getSdk} from '../types/generated';
import  packageJson from '../../package.json';

let url =( process.env.REACT_APP_ENDPOINT ? process.env.REACT_APP_ENDPOINT : "") + '/graphql';

const clientGraphql = new GraphQLClient(url);

export const SetClientJwt = (jwt => clientGraphql.setHeader("authorization", jwt));
const GraphqlClient = getSdk(clientGraphql);

export default GraphqlClient;

// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {updated} from "../redux/utente.actions";
import GraphqlClient from "../../../client/graphql.client";
import {TextInputHook} from "../../../hooks/text_input.hook";



const UtenteUpdatePasswordComponent: FunctionComponent = (props: { data: Utente, toggle: Function }) => {


    const utente_password = TextInputHook("");
    const utente_confermaPassword = TextInputHook("");


    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salva = async () => {
        try {
            setLoading(true);

            const res = await GraphqlClient.cambiaPassword(
                {
                    email: props.data.email,
                    password: utente_password.getValue(),
                }
            );
            store.dispatch(updated(res.cambiaPassword)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={true}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Aggiorna</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>

                    <FormGroup>
                        <Label for="utente_password">Password</Label>
                        <Input
                            {...utente_password.input}
                            type="password"
                            name="utente_password"
                            id="utente_password"
                            placeholder="Inserisci password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="utente_confermaPassword">Conferma password</Label>
                        <Input
                            {...utente_confermaPassword.input}
                            type="password"
                            name="utente_confermaPassword"
                            id="utente_confermaPassword"
                            placeholder="Inserisci conferma password"
                        />
                    </FormGroup>


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{
                        display:
                            'none'
                    }} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default UtenteUpdatePasswordComponent;

// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created} from "../redux/etichetteBobina.actions";
import GraphqlClient from "../../../client/graphql.client";
import Select from 'react-select';

import {TextInputHook} from "../../../hooks/text_input.hook";
import {NumberInputHook} from "../../../hooks/number_input.hook";

const ExportPackingPallet: FunctionComponent = (props: any) => {

    const [commessa, setCommessa] = useState(undefined);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [commesse, setCommesse] = useState([]);
    const [palletsLetti, setPalletsLetti] = useState([]);
    const [pallets, setPallets] = useState([]);

    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "report.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    useEffect(() => {
        const ottieniDati = async () => {
            try {
                const res = await GraphqlClient.elencoCommesse()
                setCommesse(res.elencoCommesse);
            } catch (err) {
                console.log("Errore lettura commesse", err)
                alert(err)
            } finally {
            }
        };

        ottieniDati();
    }, [])

    useEffect(() => {
        console.log('Triggero questo effetto');
        const ottieniDati = async () => {
            try {
                const res = await GraphqlClient.elencoPallets({commesaIds: [commessa.id]})
                setPalletsLetti(res.elencoPallets);
            } catch (err) {
                console.log("Errore lettura pallets", err)
                alert(err)
            } finally {
            }
        };

        if (commessa?.id) {
            ottieniDati();
        }
    }, [commessa])


    const salva = async () => {
        setLoading(true);
        try {
            const res = await GraphqlClient.stampaContenutoPallet({ids: pallets});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            downloadPDF(res.stampaContenutoPallet)
        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    const scaricaTutti = async () => {
        setLoading(true);

        try {
            const res = await GraphqlClient.stampaContenutoPallet({ids: palletsLetti});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            downloadPDF(res.stampaContenutoPallet)
        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={true}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Scarica report packing pallet</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaLotto();
                }}>


                    <FormGroup>
                        <Label for="contratto">Elenco commesse</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={commessa}
                            getOptionLabel={(item) => item.descrizione}
                            onChange={value =>
                                setCommessa(value)
                            }
                            options={[{
                                value: "",
                                description: "Tipologia",
                                isDisabled: true
                            }, ...commesse]}
                            placeholder="Seleziona tipologia documento"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="contratto">Pallet</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={pallets}
                            getOptionLabel={(item) => item}
                            getOptionValue={(item) => item}
                            onChange={value =>
                                setPallets(value)
                            }
                            isMulti
                            options={[...palletsLetti]}
                            placeholder="Seleziona pallet"
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il download.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={scaricaTutti}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Tutti'}
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Scarica'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default ExportPackingPallet;

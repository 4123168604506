// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {updated} from "../redux/societa.actions";
import GraphqlClient from "../../../client/graphql.client";


import {TextInputHook} from "../../../hooks/text_input.hook";

const SocietaUpdateComponent: FunctionComponent = (props: { data: Societa, toggle: Function }) => {


    const input_id = TextInputHook("streamline");
    const input_ragioneSociale = TextInputHook("Baby");
    const input_indirizzo = TextInputHook("Agent");
    const input_companyType = TextInputHook("Credit");

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salva = async () => {
        try {
            setLoading(true);

            const res = await GraphqlClient.aggiornaSocieta(
                {
                    input: {
                        id: input_id.getValue(),
                        ragioneSociale: input_ragioneSociale.getValue(),
                        indirizzo: input_indirizzo.getValue(),
                        companyType: input_companyType.getValue(),
                        comune: "",
                        partitaIva: "",
                        recapito: "",
                    }
                }
            );

            store.dispatch(updated(res.aggiornaSocieta)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={true}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Aggiorna</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>


                    <FormGroup>
                        <Label for="input_id">input_id</Label>
                        <Input
                            {...input_id.input}
                            name="input_id"
                            id="input_id"
                            placeholder="Inserisci input_id"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_ragioneSociale">input_ragioneSociale</Label>
                        <Input
                            {...input_ragioneSociale.input}
                            name="input_ragioneSociale"
                            id="input_ragioneSociale"
                            placeholder="Inserisci input_ragioneSociale"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_indirizzo">input_indirizzo</Label>
                        <Input
                            {...input_indirizzo.input}
                            name="input_indirizzo"
                            id="input_indirizzo"
                            placeholder="Inserisci input_indirizzo"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_companyType">input_companyType</Label>
                        <Input
                            {...input_companyType.input}
                            name="input_companyType"
                            id="input_companyType"
                            placeholder="Inserisci input_companyType"
                        />
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{
                        display:
                            'none'
                    }} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default SocietaUpdateComponent;

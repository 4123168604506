// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created, updated} from "../redux/report.actions";
import GraphqlClient from "../../../client/graphql.client";

import {TextInputHook} from "../../../hooks/text_input.hook";
import {NumberInputHook} from "../../../hooks/number_input.hook";
import moment from "moment";
import Select from "react-select";

import {FilePond, File, registerPlugin} from 'react-filepond'
import * as FilePondObj from 'filepond';

import 'filepond/dist/filepond.min.css'


import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

FilePondObj.registerPlugin(FilePondPluginFileEncode);

const ReportUpdateComponent: FunctionComponent = (props: { data: any, toggle: Function }) => {

    const input_descrizione = TextInputHook(props.data.descrizione);
    const input_querySQL = TextInputHook(props.data.querySql);

    const [elencoCommesse, setElencoCommesse] = useState(store.getState().commesse.data);
    const [commessaSelezionata, setCommessaSelezionata] = useState({});
    const [tipologiaSelezionata, setTipologiaSelezionata] = useState({});
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [contenuto, setContenuto] = useState(props.data.contenuto);

    const elencoTipologie = [
        {label: "Packing pallet", value: "Packing pallet"},
        {label: "Verbale", value: "Verbale"},
        {label: "Testapacco", value: "Testapacco"},
        {label: "DDT", value: "DDT"},
    ]

    const salva = async () => {
        if (contenuto.length === 0) {
            alert("Inserire file template")
            return
        }

        try {
            setLoading(true);
            const res = await GraphqlClient.aggiornaReport(
                {
                    input: {
                        id: props.data.id,
                        dataCreazione: moment.now(),
                        utente: "",
                        commessaId: commessaSelezionata?.id,
                        descrizione: input_descrizione.getValue(),
                        contenuto: contenuto,
                        tipologiaReport: tipologiaSelezionata.value,
                        querySql: input_querySQL.getValue()
                    }
                }
            );

            store.dispatch(updated(res.aggiornaReport)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    const updateFile = async (files) => {
        setFiles(files)
        let content = await files[0].getFileEncodeBase64String();
        setContenuto(content)
    }

    const caricaDati = () => {

        if (props.data.commessaId == "default") {
            setCommessaSelezionata({id: "default", descrizione: "default"})
        } else {
            elencoCommesse.forEach((el) => {
                    if (el.id == props.data.commessaId) {
                        setCommessaSelezionata(el)
                    }
                }
            )
        }
        elencoTipologie.forEach((el) => {
                if (el.label == props.data.tipologiaReport) {
                    setTipologiaSelezionata(el)
                }
            }
        )

    }

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

        caricaDati()

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica report</h6>
            </div>
            <ModalBody>

                <FormGroup>
                    <Label for="input_commessaId">Commessa</Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={commessaSelezionata}
                        getOptionLabel={(item) => item.descrizione}
                        onChange={value =>
                            setCommessaSelezionata(value)}
                        options={[{id: "default", descrizione: "default"}, ...elencoCommesse]}
                        placeholder="Seleziona commessa"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="input_descrizione">Descrizione</Label>
                    <Input
                        {...input_descrizione.input}
                        name="input_descrizione"
                        id="input_descrizione"
                        placeholder="Inserisci input_descrizione"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="input_contenuto">Modello report</Label>
                    <FilePond
                        files={files}
                        onupdatefiles={updateFile}
                        allowMultiple={false}
                        name="files"
                        labelIdle='Drag & Drop i tuoi  files o <span class="filepond--label-action">Sfoglia</span>'
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="input_tipologiaReport">Tipologia</Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={tipologiaSelezionata}
                        onChange={value =>
                            setTipologiaSelezionata(value)}
                        options={elencoTipologie}
                        placeholder="Seleziona commessa"
                    /> </FormGroup>
                <FormGroup>
                    <Label for="input_querySQL">Query SQL:</Label>
                    <Input
                        {...input_querySQL.input}
                        name="input_querySQL"
                        id="input_querySQL"
                        placeholder="Inserisci query SQL"
                        type={"textarea"}
                    />
                </FormGroup>

                {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                <button style={{display: 'none'}} type="submit"/>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ReportUpdateComponent;

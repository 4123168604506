// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created} from "../redux/report.actions";
import GraphqlClient from "../../../client/graphql.client";

import {TextInputHook} from "../../../hooks/text_input.hook";
import {NumberInputHook} from "../../../hooks/number_input.hook";
import moment from "moment";
import Select from "react-select";

import {FilePond, File, registerPlugin} from 'react-filepond'
import * as FilePondObj from 'filepond';

import 'filepond/dist/filepond.min.css'


import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

FilePondObj.registerPlugin(FilePondPluginFileEncode);

const ReportCreateComponent: FunctionComponent = (props: any) => {


    const input_descrizione = TextInputHook("");
    const input_querySQL = TextInputHook("");

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [elencoCommesse, setElencoCommesse] = useState(store.getState().commesse.data);

    const [commessaSelezionata, setCommessaSelezionata] = useState({});
    const [tipologiaSelezionata, setTipologiaSelezionata] = useState({});

    const [files, setFiles] = useState([])


    const salva = async () => {

        if (files.length === 0) {
            alert("Inserire file template")
            return
        }

        const b64 = files[0].getFileEncodeBase64String()
        try {
            setLoading(true);

            const res = await GraphqlClient.creaReport(
                {
                    input: {
                        id: "",
                        dataCreazione: moment.now(),
                        utente: "",
                        commessaId: commessaSelezionata?.id,
                        descrizione: input_descrizione.getValue(),
                        contenuto: b64,
                        tipologiaReport: tipologiaSelezionata.value,
                        querySql: input_querySQL.getValue()
                    }
                }
            );

            store.dispatch(created(res.creaReport)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo report</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>


                    <FormGroup>
                        <Label for="input_commessaId">Commessa</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={commessaSelezionata}
                            getOptionLabel={(item) => item.descrizione}
                            onChange={value =>
                                setCommessaSelezionata(value)}
                            options={[{id: "default", descrizione: "default"}, ...elencoCommesse]}
                            placeholder="Seleziona commessa"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_descrizione">Descrizione</Label>
                        <Input
                            {...input_descrizione.input}
                            name="input_descrizione"
                            id="input_descrizione"
                            placeholder="Inserisci input_descrizione"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_contenuto">Modello report</Label>
                        <FilePond
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={false}
                            name="files"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_tipologiaReport">Tipologia</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={tipologiaSelezionata}
                            onChange={value =>
                                setTipologiaSelezionata(value)}
                            options={[
                                {label: "Packing pallet", value: "Packing pallet"},
                                {label: "Verbale", value: "Verbale"},
                                {label: "Testapacco", value: "Testapacco"},
                                {label: "DDT", value: "DDT"},
                            ]}
                            placeholder="Seleziona commessa"
                        /> </FormGroup>
                    <FormGroup>
                        <Label for="input_querySQL">Query SQL:</Label>
                        <Input
                            {...input_querySQL.input}
                            name="input_querySQL"
                            id="input_querySQL"
                            placeholder="Inserisci query SQL"
                            type={"textarea"}
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default ReportCreateComponent;

import {createStore, applyMiddleware} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './reducers'
import {createLogger} from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas';

// -- SETTINGS
const enableLog = false;
// -- END SETTINGS

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
    sagaMiddleware
];

if (enableLog) middlewares.push(loggerMiddleware);

const createStoreWithMiddleware = applyMiddleware(
    ...middlewares
)(createStore);


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['login', 'commesse', 'societa'],
    debug: true
}


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStoreWithMiddleware(persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const persistor = persistStore(store);
export default store;

sagaMiddleware.run(sagas)


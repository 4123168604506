import {combineReducers} from 'redux'
/*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR IMPORT*/
import ddt from "../pages/ddt/redux/ddt.reducer.js"
import report from "../pages/report/redux/report.reducer.js"
import palletView from "../pages/palletView/redux/palletView.reducer.js"
import societa from "../pages/societa/redux/societa.reducer.js"
import commesse from "../pages/commesse/redux/commesse.reducer.js"
import etichetteBobina from "../pages/etichetteBobina/redux/etichetteBobina.reducer.js"
import barcodeSequenza from "../pages/barcodeSequenza/redux/barcodeSequenza.reducer.js"
import movimento from "../pages/movimento/redux/movimento.reducer.js"
import utente from "../pages/utente/redux/utente.reducer.js"
import LoginReducer from '../pages/Login/redux/login.reducer'

export default combineReducers({
    // Reducers here
    login: LoginReducer,
    /*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR REDUCER*/
ddt,

report,

    palletView,

    societa,

    commesse,

    etichetteBobina,

    barcodeSequenza,

    movimento,

    utente,

})

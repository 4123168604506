// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created, updated} from "../redux/etichetteBobina.actions";
import GraphqlClient from "../../../client/graphql.client";

import {TextInputHook} from "../../../hooks/text_input.hook";
import {NumberInputHook} from "../../../hooks/number_input.hook";
import Select from "react-select";

const EtichetteBobinaUpdateComponent: FunctionComponent = (props: any) => {


    const input_descrizione = TextInputHook(props.data.descrizione);
    const input_quantita = NumberInputHook(props.data.al - props.data.dal+1);
    const input_zpl = TextInputHook(props.data.zpl);
    const input_data = TextInputHook(props.data.dataArray);
    const input_larghezza = NumberInputHook(props.data.width_mm);
    const input_altezza = NumberInputHook(props.data.height_mm);


    const [tipologia, setTipologia] = useState({label: props.data.tipologia, value: props.data.tipologia});

    const [commessaSelezionata, setCommessaSelezionata] = useState(props.data.commessa);
    const [elencoCommesse, setElencoCommesse] = useState(store.getState().commesse.data);

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salva = async () => {
        try {
            setLoading(true);

            const res = await GraphqlClient.aggiornaEtichetteBobina(
                {
                    input: {
                        id: props.data.id,
                        dataCreazione: props.data.dataCreazione,
                        utente: "",
                        quantita: input_quantita.getValue(),
                        tipologia: tipologia.value,
                        dataArray: input_data.getValue(),
                        zpl: input_zpl.getValue(),
                        height_mm: input_altezza.getValue(),
                        width_mm: input_larghezza.getValue(),
                        commessa: JSON.stringify(commessaSelezionata),
                        descrizione: input_descrizione.getValue(),
                        stampata: props.data.stampata,
                    }
                }
            );

            store.dispatch(updated(res.aggiornaEtichetteBobina)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={true}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica bobina</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>


                    <FormGroup>
                        <Label for="input_descrizione">Descrizione</Label>
                        <Input
                            {...input_descrizione.input}
                            name="input_descrizione"
                            id="input_descrizione"
                            placeholder="Inserisci descrizione"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_commessa">Commessa</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={commessaSelezionata}
                            getOptionLabel={(item) => item.descrizione}
                            onChange={value => setCommessaSelezionata(value)}
                            options={elencoCommesse}
                            placeholder="Seleziona commessa"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_quantita">Quantità</Label>
                        <Input
                            {...input_quantita.input}
                            name="input_quantita"
                            id="input_quantita"
                            placeholder="Inserisci quantità desiderata"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_tipologia">Tipologia</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={tipologia}
                            // getOptionLabel={(item) => item.descrizione}
                            onChange={value => setTipologia(value)}
                            options={[
                                {label: "PALLET", value: "PALLET"},
                                {label: "SCATOLA", value: "SCATOLA"},
                                {label: "FALDONE", value: "FALDONE"},
                                {label: "PRATICA", value: "PRATICA"},
                                {label: "CUSTOM", value: "CUSTOM"},
                            ]}
                            placeholder="Seleziona tipologia commessa"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="input_zpl">Zebra Programming Languages</Label>
                        <Input
                            {...input_zpl.input}
                            name="input_zpl"
                            id="input_zpl"
                            placeholder="Inserisci zpl etichetta"
                            type={"textarea"}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_data">Data</Label>
                        <Input
                            {...input_data.input}
                            // onblur={input_quantita.setValue(input_data.getValue().split(/\r\n|\r|\n/).length)}
                            name="input_data"
                            id="input_data"
                            placeholder="Inserisci CSV"
                            type={"textarea"}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_larghezza">Larghezza in mm</Label>
                        <Input
                            {...input_larghezza.input}
                            name="input_larghezza"
                            id="input_larghezza"
                            placeholder="Inserire larghezza in mm"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="input_altezza">Altezza in mm</Label>
                        <Input
                            {...input_altezza.input}
                            name="input_altezza"
                            id="input_altezza"
                            placeholder="Inserire altezza in mm"
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>

                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default EtichetteBobinaUpdateComponent;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import store from '../../store/store.js'
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal
} from "reactstrap";
/*import * as loginCommands from "views/pages/Login/redux/login.commands";*/
/*
import CambioPwdComponent from "./pages/utenti/components/utenti.cambiopwd.component";
*/
import * as _ from 'lodash';
import {Tooltip} from "react-tippy";
import {BeautifyRole} from "../../pages/Login/utility";
import {userLogout} from "../../pages/Login/redux/login.actions";
import {withRouter} from 'react-router-dom';

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            modalSearch: false,
            color: "navbar-transparent",
            showCambioPwd: false
        };
    }

    toggleCambioPwd = () => {
        this.setState({showCambioPwd: !this.state.showCambioPwd})
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColor);
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white"
            });
        } else {
            this.setState({
                color: "navbar-transparent"
            });
        }
    };
    // this function opens and closes the collapse on small devices
    toggleCollapse = () => {
        if (this.state.collapseOpen) {
            this.setState({
                color: "navbar-transparent"
            });
        } else {
            this.setState({
                color: "bg-white"
            });
        }
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    // this function is to open the Search modal
    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    };

    render() {
        return (
            <>
                {/* {this.state.showCambioPwd && <CambioPwdComponent toggle={this.toggleCambioPwd} show={this.state.showCambioPwd} />}*/}
                <Navbar
                    className={classNames("navbar-absolute", {
                        [this.state.color]:
                        this.props.location.pathname.indexOf("full-screen-map") === -1
                    })}
                    expand="lg"
                >
                    <Container fluid>
                        <div className="navbar-wrapper">
                            <div className="navbar-minimize d-inline">
                                <Button
                                    className="minimize-sidebar btn-just-icon"
                                    color="link"
                                    id="tooltip209599"
                                    onClick={this.props.handleMiniClick}
                                >
                                    <i className="tim-icons icon-align-center visible-on-sidebar-regular"/>
                                    <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"/>
                                </Button>
                            </div>
                            <div
                                className={classNames("navbar-toggle d-inline", {
                                    toggled: this.props.sidebarOpened
                                })}
                            >
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={this.props.toggleSidebar}
                                >
                                    <span className="navbar-toggler-bar bar1"/>
                                    <span className="navbar-toggler-bar bar2"/>
                                    <span className="navbar-toggler-bar bar3"/>
                                </button>
                            </div>
                            <NavbarBrand href="" onClick={e => e.preventDefault()}>
                                {this.props.brandText}
                            </NavbarBrand>
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navigation"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar navbar-kebab"/>
                            <span className="navbar-toggler-bar navbar-kebab"/>
                            <span className="navbar-toggler-bar navbar-kebab"/>
                        </button>
                        <Collapse navbar isOpen={this.state.collapseOpen}>
                            <Nav className="ml-auto" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item">
                                                Mike John responded to your email
                                            </DropdownItem>
                                        </NavLink>
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item">
                                                You have 5 more tasks
                                            </DropdownItem>
                                        </NavLink>
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item">
                                                Your friend Michael is in town
                                            </DropdownItem>
                                        </NavLink>
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item">
                                                Another notification
                                            </DropdownItem>
                                        </NavLink>
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item">
                                                Another one
                                            </DropdownItem>
                                        </NavLink>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <div className="welcomeMessageWrapper">
                                    <div
                                        className={"welcomeMessage"}>Bentornato, <b>{store.getState().login?.utente?.cognome + " " + store.getState().login?.utente?.nome}</b>
                                    </div>
                                    <div
                                        className={"welcomeMessageRuolo"}>({store.getState().login?.utente?.ruolo})
                                    </div>
                                </div>


                                <UncontrolledDropdown nav>
                                    <DropdownToggle
                                        caret
                                        color="default"
                                        data-toggle="dropdown"
                                        nav
                                        onClick={e => e.preventDefault()}
                                    >
                                        <div className="photo">
                                            <img alt="..." src={require("../../assets/img/default-avatar.png")}/>
                                        </div>
                                        <b className="caret d-none d-lg-block d-xl-block"/>

                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                                        <NavLink tag="li">
                                            <DropdownItem className="nav-item" onClick={() => {
                                                store.dispatch(userLogout());
                                                this.props.history.push("/auth/login");
                                            }}>Log out</DropdownItem>
                                        </NavLink>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <li className="separator d-lg-none"/>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
                <Modal
                    modalClassName="modal-search"
                    isOpen={this.state.modalSearch}
                    toggle={this.toggleModalSearch}
                >
                    <div className="modal-header">
                        <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text"/>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalSearch}
                        >
                            <i className="tim-icons icon-simple-remove"/>
                        </button>
                    </div>
                </Modal>
            </>
        )
            ;
    }
}

export default withRouter(AdminNavbar);

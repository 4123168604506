import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/palletView.reducer';
import Fade from "react-reveal/Fade";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/palletView.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import ReactTableFilterSelectComponent from "../../components/ReactTableFilter/reactTable.filter.select.component";
import ShowConfirmationDialog from "../../components/ShowConfirmationDialog";
import Input from "reactstrap/es/Input";
import Select from "react-select";
import ExportReportModal from "./modals/exportReportModal";
import {isAdmin} from "../../utilities/constants";
import moment from "moment";


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}


class palletViewComponent extends Component {

    state = {
        data: [],
        loading: false,
        filters: {},
        commessaSelezionata: {},
        elencoCommesse: store.getState().commesse.data,
        fullText: "",
        showExport: false,
        showExportReportType: "",
        showDropDown: false,
    }

    caricaDati = async () => {
        try {
            if (!this.state.commessaSelezionata.id) {
                console.log("Nessuna commessa selezionata")
                return
            }

            const response = await GraphqlClient.elencoPalletView(
                {filter: this.state.fullText, commessaId: this.state.commessaSelezionata.id}
            );
            store.dispatch(readedAll(response.elencoPalletView)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }

    componentDidMount() {
        this.caricaDati();
    }

    getColumns = () => {
        let columns = [
            {
                Header: "pallet",
                accessor: "pallet",
                Cell: row => {
                    return <div>{row.value}</div>
                },

                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.pallet);
                },
                Filter: ({onChange}) => {
                    const filterName = 'pallet';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [filterName])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            {
                Header: "scatola",
                accessor: "scatola",
                width: 140,
                Cell: row => {
                    return <div>{row.value}
                        {/*<Button*/}
                        {/*    color="danger"*/}
                        {/*    size="sm"*/}
                        {/*    onClick={() => this.toggleDeleteScatolaAlert()}*/}
                        {/*    className={classNames("btn-icon btn-link like")}*/}
                        {/*>*/}
                        {/*    <i className="tim-icons icon-simple-remove"/>*/}
                        {/*</Button>*/}
                    </div>
                },
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.scatola);
                },
                Filter: ({onChange}) => {
                    const filterName = 'scatola';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [filterName])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            {
                Header: "faldone",
                accessor: "faldone",
                width: 140,
                Cell: row => {
                    return <div>{row.value}<Button
                        color="danger"
                        size="sm"
                        onClick={() => this.toggleDeleteFaldoneAlert(row.original.faldone)}
                        className={classNames("btn-icon btn-link like")}
                    >
                        <i className="tim-icons icon-simple-remove"/>
                    </Button></div>
                },
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.faldone);
                },
                Filter: ({onChange}) => {
                    const filterName = 'faldone';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [filterName])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            {
                Header: "numeroFaldoni",
                accessor: "numeroFaldoni",
                width: 150,

                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.numeroFaldoni);
                },
                Filter: ({onChange}) => {
                    const filterName = 'numeroFaldoni';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [filterName])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
            {
                Header: "numeroPratiche",
                accessor: "numeroPratiche",
                width: 150,
                filterMethod: (filter, row) => {
                    if (!filter || !filter?.value) return true
                    if (filter && filter.value && filter.value.length === 0) return true;
                    return filter.value.some(f => f.value === row.numeroPratiche);
                },
                Filter: ({onChange}) => {
                    const filterName = 'numeroPratiche';

                    return <ReactTableFilterSelectComponent
                        options={_.sortBy(this.props.data, [filterName])}
                        optionValue={filterName}
                        optionLabel={filterName}
                        filterName
                        value={this.state.filters[filterName]}
                        onChange={(value) => this.setState({
                            filters: {
                                ...this.state.filters,
                                [filterName]: value
                            }
                        }, () => onChange(value))}
                    />
                }
            },
        ]

        if (this.state.commessaSelezionata?.id) {
            let metaColumns = []
            this.state.commessaSelezionata.metas.forEach((meta) => {
                meta.list.forEach((m) => {

                    let metaName = capitalize(meta.description.toLowerCase());

                    metaColumns = [...metaColumns,
                        {
                            Header: m.fieldName,
                            accessor: "metas" + metaName + "." + m.fieldName,
                            width: 150,
                            filterMethod: (filter, row) => {
                                if (!filter || !filter?.value) return true
                                if (filter && filter.value && filter.value.length === 0) return true;
                                return filter.value.some(f => f.value === row["metasPratica." + m.fieldName]);
                            },
                            Filter: ({onChange}) => {
                                const filterName = 'metasPratica.' + m.fieldName;

                                return <ReactTableFilterSelectComponent
                                    options={_.sortBy(this.props.data, [filterName])}
                                    optionValue={filterName}
                                    optionLabel={filterName}
                                    filterName
                                    value={this.state.filters[filterName]}
                                    onChange={(value) => this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            [filterName]: value
                                        }
                                    }, () => onChange(value))}
                                />
                            }

                        },
                    ]
                })
            })
            columns = [...columns, ...metaColumns]
        }

        return columns
    }

    async toggleDeleteFaldoneAlert(faldone) {
        const doAction = await ShowConfirmationDialog({
            title: "Eliminazione faldone",
            text: "Sei certo di voler procedere all'eliminazione del faldone e di tutte le ptratiche al suo interno ? Attenzione l'azione è irreversibile",
        });

        if (doAction.confirm === true) {
            try {
                GraphqlClient.eliminaFaldone({faldone: faldone})
            } catch (e) {
                console.log('Impossibile eliminare faldone', e);
            }
        }

    }

    toggleShowDropDown = () => (this.setState({showDropDown: !this.state.showDropDown}));
    toggleShowExport = (reportType) => (this.setState({
        showExport: !this.state.showExport,
        showExportReportType: reportType
    }));



    render() {
        return (
            <>
                {/*Contenuto pagina*/}
                {
                    this.state.showExport ? <ExportReportModal
                        toggle={this.toggleShowExport}
                        data={{
                            commessaSelezionata: this.state.commessaSelezionata,
                            reportType: this.state.showExportReportType,
                        }}/> : null
                }
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={this.state.commessaSelezionata}
                                                getOptionLabel={(item) => item.descrizione}
                                                onChange={value =>
                                                    this.setState({commessaSelezionata: value}, () => this.caricaDati())
                                                }
                                                options={[...this.state.elencoCommesse]}
                                                placeholder="Seleziona commessa"
                                            />
                                        </Col>

                                        <Col className="d-flex justify-content-end">
                                            <ButtonDropdown isOpen={this.state.showDropDown}
                                                            toggle={this.toggleShowDropDown}>
                                                <DropdownToggle className="btn btn-primary" caret>
                                                    Reports {this.state.downloadReportInProgress ? "(Download in corso...)" : null}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => this.toggleShowExport("Testapacco")}>Testapacco</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.toggleShowExport("Packing pallet")}>Packing
                                                        pallet</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.toggleShowExport("Verbale")}>Verbale</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.toggleShowExport("Verbale CSV")}>Tracciato
                                                        CSV</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.toggleShowExport("CSV Marica/Postel")}>Tracciato
                                                        Marica/Postel</DropdownItem>
                                                        <DropdownItem
                                                        onClick={() => this.toggleShowExport("CSV Marica/Dorsoduro")}>Tracciato
                                                        Marica/Dorsoduro</DropdownItem>
                                                        <DropdownItem
                                                        onClick={() => this.toggleShowExport("CSV Marica/Postel Faldone")}>Tracciato
                                                        Marica/Postel Faldone</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={12} md={2}>
                                            <CardTitle tag="h4">Elenco pallets</CardTitle>
                                        </Col>
                                        <Col xs={12} sm={12} md={10}>
                                            <Input
                                                onChange={(e) => {
                                                    if (e.target.value.length >= 3) {
                                                        this.setState({fullText: e.target.value}, () => {
                                                            this.caricaDati()
                                                        })
                                                    } else {
                                                        this.setState({fullText: e.target.value})
                                                    }
                                                }}
                                                value={this.state.fullText} placeHolder="Ricerca...."/>
                                        </Col>
                                        <Fade when={this.props.loading}>
                                            <CardSubtitle>Caricamento in corso <i
                                                className="fa fa-spin fa-spinner"/></CardSubtitle>
                                        </Fade>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={this.getColumns()}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "palletView.data", []),
        loading: get(state, "palletView.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(palletViewComponent)
